import React, { Component } from 'react';
import { ReactComponent as FavoriteIcon } from '../../../../src/Icons/icon-favorite.svg';

export default class RideNumber extends Component {
  render() {
    if (this.props.value !== null) {
      return (
        <div>
          <div className="one-line">
            <div>{this.props.value.first_name}</div>
            {this.props.favorited && <FavoriteIcon className="icon-fave-chauffeur" alt="favorite-icon" />}
          </div>
          <div>{this.props.value.phone_number}</div>
        </div>
      );
    } else {
      return <div className="not-available" />;
    }
  }
}
