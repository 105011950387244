import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/status.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        STATUS
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'paid',
  accessor: row => (row.paid ? 'Paid' : 'Not Paid'),
  resizable: false,
  Cell: CellProps => (CellProps.original.paid ? 'Paid' : 'Not Paid'),
  className: 'paid'
};
