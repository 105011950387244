import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedComponent from '../../components/ProtectedComponent';
import RideTable from '../../components/table_components/RideTable';
import PrincipalColumns from '../../components/table_components/PrincipalColumns';
import left from '../../components/table_components/table_pagination/left.svg';
import AppStore from '../../components/images/apps/appStore.svg';
import GooglePlay from '../../components/images/apps/googlePlay.svg';
import pilotPhone from '../../components/images/apps/pilot@2x.png';
import '../MobileApp.scss';
import ReactGA from 'react-ga';

class InvitePrincipal extends ProtectedComponent {
  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="content-above-footer">
        <div className="main-content">
          <Link to="/apps" className="back">
            <img src={left} alt="" className="arrow" />
            BACK TO ALL MOBILE APPS
          </Link>
          <div className="header">Invite a Pilot to the Fortis Pilot App</div>
          <div className="content">
            <div className="table">
              <RideTable search={false} columnList={PrincipalColumns} class={'invite-table'} />
            </div>
            <div className="invite-card">
              <div className="features">
                <div className="features-header">Features</div>
                <div className="features-list">
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    Report and update your estimated wheels-down time to ensure your chauffeur is ready and waiting.
                  </div>
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    Receive advance notifications of your principal’s expected arrival time at the plane for departure.
                  </div>
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    See information about chauffeurs assigned to your upcoming trips.
                  </div>
                </div>
                <div className="getApp-btn">
                  <a href="https://apps.apple.com/us/app/fortis-pilot/id837189744?ls=1">
                    <img src={AppStore} alt="" className="download-app" />
                  </a>
                  <a href="http://play.google.com/store/apps/details?id=com.fortisriders.fortispilot.pilot">
                    <img src={GooglePlay} alt="" className="download-app" />
                  </a>
                </div>
              </div>
              <div className="phone">
                <img src={pilotPhone} alt="" className="phone-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvitePrincipal;
