import React, { Component } from 'react';
import Phone from '../images/Phone.svg';
import Mail from '../images/Email.svg';
import { withTranslation } from 'react-i18next';

class Footer extends Component {
  render() {
    const { t } = this.props;
    const feedbackLink = (
      <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/9K9dMTnaWTm3v8AE9" className="u-underline">
        Submit Feedback.
      </a>
    );

    return (
      <div className="footer">
        <div>{feedbackLink} Questions? Contact us. We'd love to help.</div>
        <div>
          <a href={'tel:' + t('common.phone.raw')}>
            <img className="icon" src={Phone} alt="Fortis Phone" />
            {t('common.phone.display')}
          </a>
        </div>
        <div>
          <a href={'mailto:' + t('common.reservationsEmail')}>
            <img className="icon" src={Mail} alt="Fortis Mail" />
            {t('common.reservationsEmail')}
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Footer);
