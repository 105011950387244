import React from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import ProtectedComponent from '../components/ProtectedComponent';
import './manageCreditCards.scss';
import { ReactComponent as CreditCardIcon } from '../Icons/table-header-icon-credit-card.svg';
import { ReactComponent as DateIcon } from '../Icons/table-header-icon-date.svg';
import { ReactComponent as ActionsIcon } from '../Icons/table-header-icon-actions.svg';
import { ReactComponent as EditIcon } from '../Icons/icon-edit.svg';
import { ReactComponent as DeleteIcon } from '../Icons/icon-delete.svg';
import ActionCard from '../credit_cards/ActionCard';
import update from 'immutability-helper';
import CreditCardsApi from '../api/creditCards';

class ManageCreditCards extends ProtectedComponent {
  constructor(props) {
    super(props);
    const initialState = {
      action: null,
      actionSuccess: false,
      clientNames: [],
      creditCards: [],
      loading: true,
      selectedCard: {}
    };

    this.state = update(this.state, { $merge: initialState });
  }

  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.fetchDataAndSetInitialState();

    window.addEventListener('scroll', () => {
      this.positionActionCard();
    });
  }

  componentDidUpdate() {
    this.positionActionCard();
  }

  fetchData(successCallback, errorCallback, finallyCallback) {
    let api = new CreditCardsApi();
    api.index(
      data => {
        successCallback(data);
      },
      errorCallback,
      finallyCallback
    );
  }

  creditCardsByClient(data, cb) {
    let map = new Map();
    data.forEach(card => {
      let client = card.clientName;
      map.has(client) ? map.get(client).push(card) : map.set(client, [card]);
    });

    cb(map);
  }

  fetchDataAndSetInitialState() {
    this.fetchData(
      data => {
        this.creditCardsByClient(data, cardData => {
          let clientNames = Array.from(cardData.keys());
          let action = clientNames.length === 0 ? 'add' : null;

          this.setState({
            clientNames: clientNames.sort(),
            creditCards: cardData,
            action: action,
            actionSuccess: false
          });
        });
      },
      null,
      () => {
        this.setState({
          loading: false
        });
      }
    );
  }

  positionActionCard() {
    var actionCardZone = document.querySelector('.action-card-zone');
    if (!actionCardZone || this.state.action === null) {
      return;
    }

    // See https://stackoverflow.com/a/26039199/10285002.
    function inViewport(el) {
      var rect = el.getBoundingClientRect();
      var windowHeight = window.innerHeight || document.documentElement.clientHeight;
      var windowWidth = window.innerWidth || document.documentElement.clientWidth;
      var vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
      var horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
      return vertInView && horInView;
    }

    var footer = document.querySelector('.footer');
    var header = document.querySelector('.header-nav');

    // If no header & no footer are visible, make action card take up full viewport height.
    if (!inViewport(header) && !inViewport(footer)) {
      actionCardZone.classList.add('action-card-zone--full-vh');
    } else if (inViewport(footer) || inViewport(header)) {
      actionCardZone.classList.remove('action-card-zone--full-vh');
    }

    if (!inViewport(header)) {
      actionCardZone.classList.add('action-card-zone--past-header');
    } else {
      actionCardZone.classList.remove('action-card-zone--past-header');
    }

    if (inViewport(header) && inViewport(footer)) {
      actionCardZone.classList.add('action-card-zone--header-and-footer-visible');
    } else {
      actionCardZone.classList.remove('action-card-zone--header-and-footer-visible');
    }
  }

  displayActionCard = (e, creditCard) => {
    e.preventDefault();

    this.setState({
      action: e.target.value,
      actionSuccess: false,
      selectedCard: creditCard || {}
    });
  };

  closeActionCard = e => {
    e.preventDefault();

    if (e.target === e.currentTarget || e.target.value === 'close') {
      this.setState({
        action: null,
        actionSuccess: false,
        selectedCard: {}
      });
    }
  };

  onSuccess = data => {
    const { action, success } = data;
    if (!success) {
      return;
    }

    this.fetchData(data => {
      this.creditCardsByClient(data, cardData => {
        let clientNames = Array.from(cardData.keys());
        this.setState({
          clientNames: clientNames.sort(),
          creditCards: cardData,
          action: action,
          actionSuccess: true,
          selectedCard: {}
        });
      });
    });
  };

  renderCardExpiration(creditCard) {
    if (creditCard.expired) {
      return <span className="label label--small label--expired">EXPIRED</span>;
    }
    return (
      <span>
        {creditCard.expirationMonth}/{creditCard.expirationYear}
      </span>
    );
  }

  renderCardNotes(notes) {
    if (!notes) {
      return null;
    }

    return <div className="note">{notes}</div>;
  }

  determineActionCardBackground() {
    if (this.state.action === null) {
      return 'action-card-zone action-card-zone--no-action';
    } else {
      return 'action-card-zone';
    }
  }

  setCardTypeBackground(creditCard) {
    return `label label--small label--${creditCard.cardTypeCode.toLowerCase()}`;
  }

  renderCardRow(creditCard) {
    const dateAdded = moment(creditCard.createdAt).format('DD MMM YYYY'),
      expiredClass = creditCard.expired ? 'row--expired' : '',
      selectedClass = this.state.selectedCard && this.state.selectedCard.id === creditCard.id ? 'row--selected' : '';

    return (
      <div className={`row ${expiredClass} ${selectedClass}`} key={`cc-${creditCard.id}`}>
        <div className="column column--card">
          <div className={`${this.setCardTypeBackground(creditCard)}`}>{creditCard.cardTypeShortName}</div>
          <span className="u-margin-right-s">• • • •</span>
          {creditCard.lastFour}
          <span className="separator">|</span>
          {this.renderCardExpiration(creditCard)}
        </div>
        <div className="column column--date-added">{dateAdded}</div>
        <div className="column column--actions">
          <div className="position-action-icons">
            <div
              className="icon-container"
              onClick={e => {
                e.target.value = 'edit';
                this.displayActionCard(e, creditCard);
              }}
            >
              <EditIcon alt="Edit" />
            </div>
            <div
              className="icon-container"
              onClick={e => {
                e.target.value = 'delete';
                this.displayActionCard(e, creditCard);
              }}
            >
              <DeleteIcon alt="Delete" />
            </div>
          </div>
        </div>

        {this.renderCardNotes(creditCard.notes)}
      </div>
    );
  }

  renderCardRows(creditCards) {
    return creditCards.map(cc => {
      return this.renderCardRow(cc);
    });
  }

  renderClientTableComponent(clientName, creditCards) {
    return (
      <React.Fragment key={clientName}>
        <div className="page-header page-header--h2">{clientName}</div>
        <div className="table">
          <div className="table-headers-container">
            <div className="column column--card">
              <CreditCardIcon className="table-header-icon table-header-icon--credit-card" alt="Credit Card" />
              Card
            </div>
            <div className="column column--date-added">
              <DateIcon className="table-header-icon table-header-icon--date" alt="Date" />
              Date Added
            </div>
            <div className="column column--actions">
              <ActionsIcon className="table-header-icon table-header-icon--actions" alt="Actions" />
              Actions
            </div>
          </div>
          {this.renderCardRows(creditCards)}
        </div>
      </React.Fragment>
    );
  }

  renderClientTable() {
    if (this.state.clientNames.length === 0) {
      return (
        <React.Fragment>
          <div className="page-header page-header--h2">There are no cards associated with your account.</div>
          <div className="cards-illustration cards-illustration--behind-table" />
        </React.Fragment>
      );
    }

    return this.state.clientNames.map(clientName => {
      return this.renderClientTableComponent(clientName, this.state.creditCards.get(clientName));
    });
  }

  renderTableAndActions() {
    if (this.state.loading) {
      /* TODO: make a LoadingIndicator component */
      return (
        <div className="page-loading-indicator-container">
          <div className="circle-loader circle-loader--large" />
        </div>
      );
    }
    return (
      <div className="cc-table-and-actions">
        <div className="credit-card-tables-container">{this.renderClientTable()}</div>
        <div className={this.determineActionCardBackground()}>
          {this.state.action !== null ? (
            <ActionCard
              action={this.state.action}
              success={this.state.actionSuccess}
              onClose={this.closeActionCard}
              onSuccess={this.onSuccess}
              creditCard={this.state.selectedCard}
            />
          ) : (
            <div className="cards-illustration" />
          )}
        </div>
      </div>
    );
  }

  render() {
    const buttonVisbilityClass =
      this.state.loading || (this.state.action === 'add' && !this.state.actionSuccess) ? 'u-hide' : '';
    return (
      <div className="credit-cards-page content-above-footer">
        <div className="main-content">
          <div className="page-header">
            <span>Manage Credit Cards</span>
            <button className={buttonVisbilityClass} onClick={this.displayActionCard} value="add">
              Add a New Card
            </button>
          </div>
          {this.renderTableAndActions()}
        </div>
      </div>
    );
  }
}

export default ManageCreditCards;
