import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/ride.svg';
import iconRideTracking from '../../images/iconRideTrackingSmall.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        RIDE #
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'id',
  width: 155,
  accessor: row => `${row.id}`,
  resizable: false,
  Cell: ({ row }) => (
    <div className="rideId-column-content">
      #{row._original.id}
      {row._original.ride_tracking_status === 'AVAILABLE' ? (
        <img src={iconRideTracking} className="icon-ride-tracking-in-table" alt="Ride Tracking Available Indicator" />
      ) : (
        ''
      )}
    </div>
  )
};
