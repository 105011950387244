import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/pdf.svg';
import InvoiceRequestButton from '../InvoiceRequestButton.js';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        REQUEST PDF
      </div>
    </div>
  ),
  id: 'request_pdf_url',
  accessor: row => `${row.request_pdf_url}`,
  resizable: false,
  Cell: ({ row, original, tdProps }) => (
    <InvoiceRequestButton
      invoiceId={original.id}
      invoiceNumber={original.invoice_number}
      clientId={original.client_id}
      clientName={original.client_name}
      url={original.request_pdf_url}
      targetRef={tdProps.rest.tablereference}
    />
  )
};
