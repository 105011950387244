import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/principal.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        PRINCIPAL
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'passenger_name',
  accessor: d => d.passenger_name,
  resizable: false
};
