import React, { Component } from 'react';
import axios from 'axios';
import TextInputField from '../components/TextInputField';
import LoadingButton from '../components/LoadingButton';
import './SignIn.scss';
import '../components/TextInputField.scss';

const initialState = {
  email: '',
  querying: false
};

const isLoginDataValid = state => state.email.length > 5;

const isEmailTyped = state => state.email.length === 0;

export default class AcceptPortal extends Component {
  state = initialState;

  acceptPortal = e => {
    const self = this;
    this.setState({ querying: true });

    e.preventDefault();
    axios
      .post(process.env.REACT_APP_GD2_URL + '/portal_apis/clients/invitation/accept_portal.json', {
        email: this.state.email,
        invitation_token: window.location.href.split('=')[1]
      })
      .then(() => {
        self.props.history.push('/sign-in');
      })
      .catch(err => {
        console.error(err);
        this.setState({ querying: false });
      });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const enabled = isLoginDataValid(this.state) && !this.state.querying;
    const enabledUnderEmail = isEmailTyped(this.state);
    return (
      <div className="password-page content-above-footer">
        <div className="main-content">
          <div className="page-header u-center-text">You're almost there!</div>
          <div>
            <div className="password--card">
              <form onSubmit={this.acceptPortal} className="input-field-suggestion-copy">
                <div className="card-header-text">Please confirm your account.</div>
                <TextInputField
                  name="email"
                  label="EMAIL"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.email}
                  required={true}
                  showUnderline={enabledUnderEmail}
                />
                <LoadingButton
                  title="Let's go!"
                  enabled={enabled}
                  animating={this.state.querying}
                  onClick={this.acceptPortal}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
