import React from 'react';
import './pickupHeader.scss';

const pickupHeader = () => (
  <div className="pickupHeader">
    <div className="page-header u-margin-bottom-m">Timing is everything.</div>
    <div className="subhead">Use our pickup time calculator to determine the best pickup time for your situation.</div>
    <div className="recommendation">
      Recommendations are estimates based on typical travel times. Your Fortis agent can give you real-time information
      if there is a special event or heavy traffic.
    </div>
    <div className="header2">Pickup Time Calculator</div>
  </div>
);

export default pickupHeader;
