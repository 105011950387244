import React, { Component } from 'react';

export default class rideNumbers extends Component {
  rideStatusValue = resStatus => {
    if (resStatus === 'CANCELLED' || resStatus === 'LATE-CANCELLED-AFFILIATE-ONLY') {
      return <div className="reservation-status-warning">Reservation Status: Cancelled</div>;
    } else if (resStatus === `LATE-CANCELLED`) {
      return <div className="reservation-status-warning">Reservation Status: Late Cancelled</div>;
    } else if (resStatus === 'RESERVED' || resStatus === 'DEFERRED') {
      return <div>Reservation Status: Confirmed</div>;
    } else if (resStatus === 'QUOTE') {
      return <div>Reservation Status: Quote</div>;
    }
  };

  render() {
    const { reservation } = this.props;

    if (reservation === undefined || reservation === null) {
      return <div className="header-details"></div>;
    }

    return (
      <div className="ride-id-row">
        <div className="label">Ride: #{reservation.id}</div>
        <div className={`${reservation.roadshow_id ? 'label' : 'u-hide'}`}>Ride Set: #{reservation.roadshow_id}</div>
        <div className="reservation-status">{this.rideStatusValue(reservation.status.code)}</div>
      </div>
    );
  }
}
