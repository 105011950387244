import React, { Component } from 'react';
import './pickupInput.scss';
import TextInputField from '../components/TextInputField';

class pickupInput extends Component {
  render() {
    const { arrival, label, name, index, onChange, value, showUnderline, placeholder, error } = this.props;
    return (
      <div className="icon-and-input-lockup">
        <div className={`icon ${arrival ? 'icon--clock' : 'icon--pin'}`}>
          <div className="input-lockup__label">{index}</div>
        </div>
        <TextInputField
          name={name}
          label={label}
          type="text"
          onChange={onChange}
          value={value}
          required={true}
          showUnderline={showUnderline}
          placeholder={placeholder}
          error={error}
        />
      </div>
    );
  }
}

export default pickupInput;
