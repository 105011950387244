import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './searchBar.scss';
import Search from '../images/Search.svg';

export default class SearchBar extends Component {
  render() {
    if (this.props.enabled) {
      const needsBox = ['invite-table', 'invoice-table'].indexOf(this.props.className) > -1;
      const searchClass = needsBox ? 'search-bar-boxed' : 'search-bar';

      return (
        <div className={searchClass}>
          <div className="search-row">
            <img src={Search} alt="lol" className="search-icon" />
            <input
              value={this.props.value}
              onChange={this.props.onChange}
              placeholder={`Search by ${this.props.placeholder}.`}
              className={this.props.value === '' ? 'search-input italic' : 'search-input'}
            ></input>
          </div>
          <div className="search-line" />
        </div>
      );
    } else {
      return null;
    }
  }
}

SearchBar.propTypes = {
  enabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

SearchBar.defaultProps = {
  placeholder: 'invoice, amount, or date'
};
