import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './FRHeader.scss';

export default class DropDown extends Component {
  handleClick = () => {
    if (window.location.href.split('/')[3] === 'change-password') {
      window.location.reload(true);
    }
  };

  render() {
    return (
      <div className="dropdown" onClick={this.props.closePopup}>
        <Link to="/credit-cards" className="action-link">
          Manage Credit Cards
        </Link>
        <Link to="/change-password" onClick={this.handleClick} className="action-link">
          Change Password
        </Link>
        <Link to="/sign-out" className="action-link">
          Sign Out
        </Link>
      </div>
    );
  }
}
