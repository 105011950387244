import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/quote.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        TOTAL IN USD
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'total_amount',
  accessor: row => parseInt(row.total_amount.split('$')[1]),
  resizable: false,
  Cell: CellProps => CellProps.original.total_amount,
  className: 'total_amount'
};
