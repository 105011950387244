import React, { Component } from 'react';
import RideOverviewDetail from './rideOverviewDetail';
import './rideOverview.scss';

export default class rideOverview extends Component {
  render() {
    let alias = null,
      reservation = this.props.reservation,
      { passenger, chauffeur, waypoints, passenger_preferred_chauffeur } = reservation,
      pickupWaypoint = waypoints.find(waypoint => waypoint.waypoint_type === 'PICKUP');

    if (passenger && passenger.travel_name !== passenger.name) {
      alias = `Traveling as ${passenger.travel_name}`;
    }

    const pluralizeCustom = (num, noun, suffix = 's') => `${noun}${num !== 1 ? suffix : ''}`;
    let principalHeader = pluralizeCustom(reservation.passenger_count, 'passenger').toUpperCase();
    let numOthers = reservation.passenger_count - 1;
    let othersContent = numOthers > 0 ? ` and ${numOthers} ${pluralizeCustom(numOthers, 'other')}` : '';
    let principalContent = `${passenger && passenger.name}` + othersContent;
    
    let plateDetails = reservation.vehicle_plate ? ` (${reservation.vehicle_plate})` : '';
    let vehicleContent = `${reservation.vehicle_type.name}${plateDetails}`;

    return (
      <div className="overview">
        <div className="u-margin-bottom-l subhead">Overview</div>
        <RideOverviewDetail
          header={`CHAUFFEUR`}
          content={chauffeur && chauffeur.first_name}
          content_continued={chauffeur && chauffeur.phone_number}
          image={chauffeur && chauffeur.picture_url}
          alt="Chauffeur"
          favoritedChauffeur={passenger_preferred_chauffeur}
          reservation={reservation}
        />
        <RideOverviewDetail
          header={principalHeader}
          content={principalContent}
          content_continued={null}
          alias={alias}
          alt="Principal"
        />
        <RideOverviewDetail header={`VEHICLE`} content={vehicleContent} alt="Vehicle" />
        <RideOverviewDetail
          header={`PICKUP DETAILS`}
          content={reservation.formattedStart}
          content_continued={`${pickupWaypoint.location.name}${
            pickupWaypoint.tail_number ? ` off ${pickupWaypoint.tail_number}` : ''
          }`}
          alt="Pickup Details"
        />
        <RideOverviewDetail
          header={`ESTIMATED TOTAL IN USD`}
          content={reservation.estimated_total_cost_formatted_usd}
          content_continued={null}
          alt="Total Price"
        />
      </div>
    );
  }
}
