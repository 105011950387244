import React, { Component } from 'react';

export default class waypointHeader extends Component {
  formatCityState() {
    const { city, state, country } = this.props;
    return `${city}, ${state && state.code ? `${state.code}, ` : ''}${country && country.code}`;
  }

  formatFullAddress() {
    const { address } = this.props;
    return (
      <React.Fragment>
        {address}
        <div>{this.formatCityState()}</div>
      </React.Fragment>
    );
  }

  createTitle() {
    const { name, city } = this.props;
    if (name === 'As Directed') {
      return name;
    } else if (name === city) {
      return this.formatCityState();
    } else if (!name) {
      return this.formatFullAddress();
    } else {
      return name;
    }
  }

  render() {
    const { name, city, phone, tail_number, airline_code, flight_number } = this.props;

    return (
      <div>
        <div className="waypoint-title section">{this.createTitle()}</div>
        <div className={!name || name === 'As Directed' || name === city ? 'u-hide' : 'section'}>
          {this.formatFullAddress()}
        </div>
        <div className={phone ? 'section' : 'u-hide'}>Phone: {phone}</div>
        <div className={tail_number ? 'section' : 'u-hide'}>Tail: {tail_number}</div>
        <div className={flight_number && airline_code ? 'section' : 'u-hide'}>
          Flight: {airline_code}-{flight_number}
        </div>
      </div>
    );
  }
}
