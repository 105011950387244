import React from 'react';
import ProtectedComponent from '../ProtectedComponent';
import './FRHeader.scss';
import down from './../table_components/table_pagination/down.svg';
import DropDown from './DropDown';
import update from 'immutability-helper';

class UserWelcome extends ProtectedComponent {
  constructor(props) {
    super(props);
    const initialState = { showPopup: false };
    this.state = update(this.state, { $merge: initialState });
  }

  togglePopup = e => {
    e.preventDefault();
    this.setState({
      showPopup: !this.state.showPopup
    });
  };

  render() {
    return (
      <React.Fragment>
        <li className="header-text">|</li>
        <li className="header-text" onClick={this.togglePopup.bind(this)}>
          Welcome, {this.state.user.name}
          <img src={down} alt="" className="down-arrow" onClick={this.togglePopup.bind(this)} />
        </li>
        {this.state.showPopup ? <DropDown text="Contact Fortis" closePopup={this.togglePopup.bind(this)} /> : null}
      </React.Fragment>
    );
  }
}

export default UserWelcome;
