import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/date.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        INVOICE DATE
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'invoiced_date',
  accessor: row => `${row.invoiced_date}`,
  resizable: false,
  Cell: CellProps => CellProps.original.formattedInvoicedDate,
  className: 'invoice_date'
};
