import React, { Component } from 'react';
import PickupInput from './pickupInput';
import LoadingButton from '../components/LoadingButton';
import './pickupForm.scss';

const isFormDataValid = props =>
  props.pickup.value.length > 0 && props.destination.value.length > 0 && props.arrival.value.length > 0;

const isPickupTyped = props => props.pickup.value.length === 0;
const isDestinationTyped = props => props.destination.value.length === 0;
const isArrivalTyped = props => props.arrival.value.length === 0;

class pickupForm extends Component {
  render() {
    const { pickup, arrival, destination, onChange, querying, handleSubmit } = this.props;
    const enabled = isFormDataValid(this.props) && !querying;
    const enabledUnderPickup = isPickupTyped(this.props);
    const enabledUnderDestination = isDestinationTyped(this.props);
    const enabledUnderArrival = isArrivalTyped(this.props);
    return (
      <div className="form">
        <div className="subhead">Enter Your Ride Details</div>
        <div>
          <form onSubmit={handleSubmit}>
            <PickupInput
              index={pickup.index}
              name={pickup.name}
              label={pickup.label}
              onChange={onChange}
              value={pickup.value}
              error={pickup.error}
              showUnderline={enabledUnderPickup}
              placeholder={'Address'}
            />
            <PickupInput
              index={destination.index}
              name={destination.name}
              label={destination.label}
              onChange={onChange}
              value={destination.value}
              error={destination.error}
              showUnderline={enabledUnderDestination}
              placeholder={'Address'}
            />
            <PickupInput
              arrival={true}
              index={arrival.index}
              name={arrival.name}
              label={arrival.label}
              onChange={onChange}
              value={arrival.value}
              error={arrival.error}
              showUnderline={enabledUnderArrival}
              placeholder={'HH:MM AM/PM'}
            />
            <LoadingButton
              title="Calculate Pickup Time"
              enabled={enabled}
              animating={querying}
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default pickupForm;
