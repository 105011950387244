import axios from 'axios';

export default function ConfigureHTTPHeaders() {
  axios.defaults.headers.common['FORTIS-API-ACCOUNT-TYPE'] = 'client-portal';
  axios.defaults.headers.common['Accept'] = 'application/json';

  const currentUser = JSON.parse(localStorage.getItem('user'));
  if (currentUser) {
    axios.defaults.headers.common['FORTIS-API-ACCESS-IDENTIFIER'] = currentUser.accessIdentifier;
    axios.defaults.headers.common['FORTIS-API-ACCESS-KEY-INDEX'] = currentUser.accessKeyIndex;
    axios.defaults.headers.common['FORTIS-API-ACCESS-KEY'] = currentUser.accessKey;
  }
}
