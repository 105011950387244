import React from 'react';
import './ruleOfThumb.scss';

const ruleOfThumb = () => (
  <div className="ruleOfThumb">
    <div className="card-header">Rule of Thumb</div>
    <div className="content">INTERNATIONAL FLIGHTS:</div>
    <div className="description">Arrive 3 Hours in Advance</div>
    <div className="content">DOMESTIC FLIGHTS:</div>
    <div className="description">Arrive 1½ - 2 Hours in Advance</div>
  </div>
);

export default ruleOfThumb;
