import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/due.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        DUE DATE
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'due_date',
  accessor: row => `${row.due_date}`,
  resizable: false,
  Cell: CellProps => CellProps.original.formattedDueDate,
  className: 'due_date'
};
