import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/invoice.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        INVOICE
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'id',
  accessor: row => `${row.invoice_number}`,
  resizable: false,
  className: 'invoice_number'
};
