import React from 'react';
import ProtectedComponent from '../components/ProtectedComponent';
import RideTable from '../components/table_components/RideTable';
import InvoiceColumns from '../components/table_components/InvoiceColumns';
import InvoiceRequestPopup from '../components/popup_components/InvoiceRequestPopup.js';
import update from 'immutability-helper';
import ReactGA from 'react-ga';

class Invoices extends ProtectedComponent {
  constructor(props) {
    super(props);
    const initialState = {
      requestedInvoice: false,
      requestedInvoiceId: null,
      requestedInvoiceNumber: null,
      requestedInvoiceUrl: ''
    };
    this.state = update(this.state, { $merge: initialState });
  }

  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  toggleRequestedInvoice = e => {
    e.preventDefault();
    // TODO: Do we need this `if` at all?
    if (e.target === e.currentTarget) {
      this.setState({ requestedInvoice: !this.state.requestedInvoice });
    }
  };

  render() {
    return (
      <div className="invoices content-above-footer">
        <div className="main-content">
          <div className="page-header">Invoices</div>
          <RideTable
            class={'invoice-table'}
            clickHandler={() => {
              return null;
            }}
            columnList={InvoiceColumns}
            noRecordsFoundMessage="No invoices found."
            pageSize={25}
            parentTable={this}
            search={true}
            typeFilter={'Invoice'}
            user={this.state.user}
            placeholder={'invoice, amount, or date'}
          />
          <div className="invoice-modal">
            {this.state.requestedInvoice ? (
              <InvoiceRequestPopup
                invoiceId={this.state.requestedInvoiceId}
                invoiceNumber={this.state.requestedInvoiceNumber}
                url={this.state.requestedInvoiceUrl}
                clientEmail={this.state.user.email}
                closePopup={this.toggleRequestedInvoice.bind(this)}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Invoices;
