import React, { Component } from 'react';
import './pickupCalcInfo.scss';

class pickupCalcInfo extends Component {
  render() {
    const { finish, pickupTime, distance, driveTime } = this.props;
    return (
      <div>
        {finish ? (
          <div className="pickupCalcInfo">
            <div className="v-rule" />
            <div className="calc-info">
              <div className="card-header">Suggested Pickup Time</div>
              <div className="calc-info-type">PICKUP TIME</div>
              <div className="calc-data">{pickupTime}</div>
              <div className="calc-info-type">DISTANCE</div>
              <div className="calc-data">{distance}</div>
              <div className="calc-info-type">ESTIMATED DRIVE TIME</div>
              <div className="calc-data">{driveTime}</div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default pickupCalcInfo;
