import React from 'react';
import ProtectedComponent from './components/ProtectedComponent';
import RideTable from './components/table_components/RideTable';
import ReservationColumns from './components/table_components/ReservationColumns';
import './dashboard_components/dashboard.scss';
import PopupContactFortis from './components/popup_components/PopupContactFortis';
import ReactGA from 'react-ga';

class Dashboard extends ProtectedComponent {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, this.state, { showPopup: false });
    this.togglePopup = this.togglePopup.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  username() {
    const user = this.state.user;
    return user ? user.name : '';
  }

  togglePopup = e => {
    e.preventDefault();
    this.setState({
      showPopup: !this.state.showPopup
    });
  };

  render() {
    return (
      <div className="Dashboard content-above-footer">
        {this.state.showPopup ? <PopupContactFortis closePopup={this.togglePopup} /> : null}
        <div className="main-content main-content--homepage">
          <div className="hero">
            <div className="hero-overlay"></div>
            <div className="call-to-action" onClick={this.togglePopup}>
              <div className="page-header page-header--home-promo">Welcome back, {this.username()}!</div>
              <div className="body-text">
                You and your principal are the reason Fortis exists. Make yourself at home here in your Client Portal,
                and let us know how we can serve you today.
              </div>
              <div className="contact-button">Contact Us</div>
            </div>
          </div>
          <div className="page-header">Current and Upcoming Rides</div>
          <RideTable
            class={'default'}
            columnList={ReservationColumns}
            noRecordsFoundMessage="No upcoming rides found."
            pageSize={5}
            range="upcoming"
            rideStatuses="Reserved,Deferred"
            search={false}
            status="All"
            typeFilter="Ride"
            user={this.state.user}
          />
        </div>
      </div>
    );
  }
}

export default Dashboard;
