import axios from 'axios';
import { TimeHandler } from '../time_components/timeHandler';

const finallyHandler = (component, data) => {
  // this check is to avoid the error "Can't perform a React state update on an unmounted component."
  if (localStorage.length > 0) {
    component.setState({
      portalReservations: data || [],
      loading: false
    });
  }
};

const errorHandler = (component, error) => {
  console.error(error.toJSON());

  if (error.response) {
    if (error.response.status === 401) {
      localStorage.clear();
      if (component.props.history) {
        component.props.history.push('/sign-in');
      } else {
        // fail safely if we don't have history...
        window.location = window.location.origin + '/sign-in';
      }
    } else {
      console.error(error.response);
    }
  } else if (error.request) {
    console.error(error.request);
  } else {
    console.error(error.message);
  }
};

export const rideMount = component => {
  let typeFilter = component.props.typeFilter || component.state.typeFilter;
  let range = component.props.range || component.state.range;
  let sortOrder = component.props.sortOrder || component.state.sortOrder;
  let statuses = component.props.rideStatuses || component.state.rideStatuses;
  let queryParams = [`statuses=${statuses}`];
  let data;

  switch (range) {
    case 'all':
      queryParams.push('all=true');
      break;
    case 'upcoming':
      // todo: figure out how to add the page size and offset deets here
      queryParams.push('upcoming=true');
      // continue to add the 'dashboard' param until we're certain the API supports 'upcoming' instead
      queryParams.push('dashboard=true');
      break;
    default:
    // no-op
  }

  if (sortOrder) {
    queryParams.push(`order=${sortOrder}`);
  }

  if (typeFilter === 'Invoice') {
    axios
      .get(process.env.REACT_APP_GD2_URL + `/mobile_apis/client_booking/invoices.json`)
      .then(json => {
        const invoices = json.data.invoices;
        data = TimeHandler(invoices, typeFilter);
      })
      .catch(error => {
        errorHandler(component, error);
      })
      .finally(() => {
        finallyHandler(component, data);
      });
  } else if (component.state.reservationId) {
    axios
      .get(
        process.env.REACT_APP_GD2_URL +
          `/mobile_apis/client_booking/reservations/${component.state.reservationId}?portal=1`
      )
      .then(json => {
        const ride = json.data.reservation;
        data = TimeHandler([ride], 'Ride');
      })
      .catch(error => {
        errorHandler(component, error);
      })
      .finally(() => {
        finallyHandler(component, data);
      });
  } else {
    axios
      .get(process.env.REACT_APP_GD2_URL + `/mobile_apis/client_booking/reservations.json?${queryParams.join('&')}`)
      .then(json => {
        const rides = json.data.reservations;
        data = TimeHandler(rides, 'Ride');
      })
      .catch(error => {
        errorHandler(component, error);
      })
      .finally(() => {
        finallyHandler(component, data);
      });
  }
};
