import React, { Component } from 'react';

export default class RideNumber extends Component {
  render() {
    var statusColor;
    var status = this.props.value;
    if (status === 'Upcoming') {
      statusColor = 'blue';
    } else if (status === 'Late Cancelled') {
      statusColor = 'red';
    } else if (status === 'Cancelled' || status === 'Completed') {
      statusColor = 'gray';
    } else {
      statusColor = 'green';
    }
    if (this.props.value) {
      return (
        <div className="status">
          <div className={`status-indicator ${statusColor}`}>&nbsp;</div>
          <div>{this.props.value}</div>
        </div>
      );
    } else {
      return <div className="not-available" />;
    }
  }
}
