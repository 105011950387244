import React, { Component } from 'react';
import WayPoints from './rideWaypoints';
import './rideItinerary.scss';

export default class rideItinerary extends Component {
  render() {
    return (
      <div className="itinerary">
        <div className="u-margin-bottom-l subhead">Itinerary</div>
        <WayPoints {...this.props} />
      </div>
    );
  }
}
