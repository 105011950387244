import React from 'react';
import axios from 'axios';
import ProtectedComponent from '../components/ProtectedComponent';
import update from 'immutability-helper';
import TextInputField from '../components/TextInputField';
import './Password.scss';
import '../components/TextInputField.scss';
import ReactGA from 'react-ga';
import LoadingButton from '../components/LoadingButton.js';
import Errors from '../components/Errors.js';

const initialState = {
  current_password: '',
  new_password: '',
  confirm_password: '',
  success: false,
  errors: null,
  error_message: {
    passwordLength: 'The password must be at least 8 characters.',
    passwordRequired: 'This field is required.',
    passwordMatch: 'Your new password must match.'
  },
  querying: false
};

const isLoginDataValid = state =>
  state.current_password.length > 0 &&
  state.confirm_password.length > 0 &&
  state.confirm_password === state.new_password;

const isCurrentPasswordTyped = state => state.current_password.length === 0;
const isNewPasswordTyped = state => state.new_password.length === 0;
const isConfirmPasswordTyped = state => state.confirm_password.length === 0;

export default class ChangePassword extends ProtectedComponent {
  constructor(props) {
    super(props);
    this.state = update(this.state, { $merge: initialState });
  }

  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  changePassword = e => {
    this.setState({ querying: true });
    const current_user_email = this.state.user.email;
    e.preventDefault();
    axios
      .post(process.env.REACT_APP_GD2_URL + '/portal_apis/clients/account/update_password.json', {
        password: this.state.confirm_password,
        previous_password: this.state.current_password
      })
      .then(() => {
        axios
          .post(process.env.REACT_APP_GD2_URL + '/portal_apis/clients/account/login.json', {
            email: current_user_email,
            password: this.state.confirm_password
          })
          .then(data => {
            let userData = {
              accessIdentifier: data.data.access_identifier,
              accessKey: data.data.access_key,
              accessKeyIndex: data.data.access_key_index,
              name: data.data.name,
              email: current_user_email
            };
            localStorage.setItem('user', JSON.stringify(userData));
            this.setState({ success: true, querying: false });
          })
          .catch(err => {
            console.error(err);
            this.setState({ querying: false });
          });
      })
      .catch(err => {
        console.error(err);
        let errorMessages;
        if (err.response && [400, 422].includes(err.response.status)) {
          if (err.response.data.errors) {
            errorMessages = Object.values(err.response.data.errors).map(val => val.detail);
          } else if (err.response.data.error && err.response.data.error.message) {
            errorMessages = [err.response.data.error.message];
          }
        }
        if (errorMessages === undefined) {
          errorMessages = ['An unexpected error occured please try again.'];
        }
        this.setState({ errors: errorMessages, querying: false });
      });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  createBodyContent() {
    if (this.state.success) {
      return (
        <div>
          <div className="u-margin-bottom-m">Your password has been successfully changed.</div>
          <a href="/" className="button-primary">
            View Dashboard
          </a>
        </div>
      );
    } else {
      const enabled = isLoginDataValid(this.state) && !this.state.querying;
      const enabledUnderCurrentPassword = isCurrentPasswordTyped(this.state);
      const enabledUnderNewPassword = isNewPasswordTyped(this.state);
      const enabledUnderConfirmPassword = isConfirmPasswordTyped(this.state);
      const errors = this.state.errors;

      return (
        <div className="password--card">
          <form onSubmit={this.changePassword}>
            <div className="card-header-text">Current Password</div>
            <TextInputField
              name="current_password"
              label="CURRENT PASSWORD"
              type="password"
              onChange={this.handleChange}
              value={this.state.current_password}
              required={true}
              showUnderline={enabledUnderCurrentPassword}
              autocomplete="off"
            />
            <div className="card-header-text">New Password</div>
            <TextInputField
              name="new_password"
              label="NEW PASSWORD (Minimum of 8 Characters)"
              type="password"
              onChange={this.handleChange}
              value={this.state.new_password}
              required={true}
              showUnderline={enabledUnderNewPassword}
              autocomplete="off"
            />
            <TextInputField
              name="confirm_password"
              label="CONFIRM NEW PASSWORD"
              type="password"
              onChange={this.handleChange}
              value={this.state.confirm_password}
              required={true}
              showUnderline={enabledUnderConfirmPassword}
              autocomplete="off"
            />
            <LoadingButton
              title="Change Password"
              enabled={enabled}
              animating={this.state.querying}
              onClick={this.changePassword}
            />
          </form>
          {errors ? <Errors errors={errors} /> : null}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="password-page content-above-footer">
        <div className="page-header u-center-text">Change Password</div>
        {this.createBodyContent()}
      </div>
    );
  }
}
