import React from 'react';
import './LoadingButton.scss';

export default class LoadingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHoverContent: false
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseOver() {
    this.setState({ showHoverContent: true });
  }

  handleMouseLeave() {
    this.setState({ showHoverContent: false });
  }

  maintainButtonWidth(title) {
    return (
      <React.Fragment>
        <div className="invisible-text-to-maintain-width">{title}</div>
        <div className="circle-loader" />
      </React.Fragment>
    );
  }

  render() {
    const { title, enabled, animating, klass, buttonType, onHover, onClick } = this.props;
    const buttonTitle = animating ? this.maintainButtonWidth(title) : title;
    const classesForLoading = `${klass} ${klass}--loading ${enabled ? '' : `${klass}--disabled`}`;

    return (
      <span className="loadingButton">
        <button
          type={buttonType}
          className={classesForLoading}
          onClick={onClick}
          disabled={!enabled}
          onMouseOver={onHover && this.handleMouseOver}
          onMouseLeave={onHover && this.handleMouseLeave}
        >
          {buttonTitle}
        </button>
        {this.state.showHoverContent && onHover()}
      </span>
    );
  }
}

LoadingButton.defaultProps = {
  klass: 'button-primary',
  buttonType: 'submit',
  enterhover: null,
  leaveHover: null,
  showHoverContent: false
};
