import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/pickup.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        PICKUP DETAILS
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'start',
  accessor: row => row.start,
  resizable: false,
  Cell: ({ row }) => (
    <div>
      <div className="column-pickup">{row._original.formattedStart}</div>
      <div className="column-pickup">{row._original.waypoints[0].location.city}</div>
    </div>
  )
};
