import React from 'react';
import ProtectedComponent from '../components/ProtectedComponent';
import RideTable from '../components/table_components/RideTable';
import ReservationColumns from '../components/table_components/ReservationColumns';
import './ReservationNav.scss';

var statusOptions = [
  'All',
  'Upcoming',
  'Chauffeur in Position',
  'Passenger in Vehicle',
  'Completed',
  'Cancelled',
  'Late Cancelled'
];

export default class ReservationNav extends ProtectedComponent {
  constructor(props) {
    super();
    this.state = Object.assign({}, this.state, { status: 'All' });
  }
  statusChanger = e => {
    this.setState({ status: e });
  };

  render() {
    return (
      <div className="main-content">
        <div className="header-row-container">
          <div className="u-margin-right-m">Rides</div>
          <div className="attribute-text">Displaying rides from 1 week ago to 2 weeks from now</div>
          <div className="status-dropdown u-hide">
            <div className="line-sort" />
            <div className="status-section styleSelect">
              STATUS:
              <select className="styleSelect" onChange={e => this.statusChanger(e.target.value)}>
                {statusOptions.map((option, i) => (
                  <option key={i} value={option}>
                    {`${option}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <RideTable
          class={'default'}
          columnList={ReservationColumns}
          search={false}
          sortOrder="desc"
          status={this.state.status}
          rideStatuses="Reserved,Deferred,Cancelled,Late-Cancelled,Late-Cancelled-Affiliate-Only"
          typeFilter="Ride"
          user={this.state.user}
        />
      </div>
    );
  }
}
