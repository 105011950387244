// polyfills to support IE 9 and friends
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import './i18n';
import ConfigureHTTPHeaders from './ConfigureHTTPHeaders';
import SignIn from './sign_in/SignIn';
import Welcome from './sign_in/Welcome';
import Invitation from './sign_in/Invitation';
import SignOut from './sign_out/SignOut';
import Dashboard from './Dashboard';
import Reservations from './reservation_components/Reservations';
import Reservation from './ride_view_components/rideView';
import Invoices from './invoice_components/Invoices';
import RequestApp from './request_apps/RequestApp';
import InvitePrincipal from './request_apps/principal/invitePrincipal';
import InviteAssistant from './request_apps/assistant/inviteAssistant';
import InvitePilot from './request_apps/pilot/invitePilot';
import ManageCreditCards from './credit_cards/ManageCreditCards';
import PickupCalculator from './pickup_calc/pickupCalc';
import PasswordSuccess from './password/PasswordSuccess';
import ChangePassword from './password/ChangePassword';
import ForgotPassword from './password/ForgotPassword';
import ResetPassword from './password/ResetPassword';
import IncorrectURL from './components/redirect_components/incorrectURL';
import Footer from './components/footer_components/FRFooter';
import FRHeader from './components/header_components/FRHeader';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import './browserIE.scss';

import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';

// Google Analytics tracking
import ReactGA from 'react-ga';
if (
  window.location.href.includes('clients.fortis.co') ||
  window.location.href.includes('clients-prod.fortis.co') ||
  window.location.href.includes('clients.fortisriders.com') ||
  window.location.href.includes('clients-prod.fortisriders.com')
) {
  ReactGA.initialize('UA-50495033-6');
} else {
  ReactGA.initialize('UA-50495033-5');
}

class ClientPortal extends React.Component {
  constructor(props) {
    super(props);

    ConfigureHTTPHeaders();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.state = { user: currentUser };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  routing = (
    <Router key="1">
      <FRHeader />
      <Switch>
        <Route exact path={`/reservations/:reservationId`} component={Reservation} />
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/reservations" component={Reservations} />
        <Route exact path="/invoices" component={Invoices} />
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/invitation" component={Invitation} />
        <Route exact path="/password/success" component={PasswordSuccess} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/sign-out" component={SignOut} />
        <Route exact path="/apps/principal" component={InvitePrincipal} />
        <Route exact path="/apps/assistant" component={InviteAssistant} />
        <Route exact path="/apps/pilot" component={InvitePilot} />
        <Route exact path="/apps" component={RequestApp} />
        <Route exact path="/pick-up-calculator" component={PickupCalculator} />
        <Route exact path="/credit-cards" component={ManageCreditCards} />
        <Route component={IncorrectURL} />
        <Route component={SignIn} />
      </Switch>
    </Router>
  );

  render() {
    return [this.routing, <Footer key="2" />, <SmartBanner title={'Fortis Assistant App'} key="3" />];
  }
}

ReactDOM.render(<ClientPortal />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
