import Ride from './columns/ride';
import RideSet from './columns/rideset';
import Principal from './columns/principal';
import Pickup from './columns/pickup';
import Chauffeur from './columns/chauffeur';
import Status from './columns/status';
import FilterReservations from './columns/filterReservations';

export default [
  Ride,
  RideSet,
  Principal,
  Pickup,
  Chauffeur,
  Status,
  FilterReservations
];
