import { Component } from 'react';
import ConfigureHTTPHeaders from '../ConfigureHTTPHeaders';

class ProtectedComponent extends Component {
  constructor(props) {
    super(props);
    let currentUser = JSON.parse(localStorage.getItem('user'));
    this.state = { user: currentUser };
  }

  componentDidMount() {
    const { history } = this.props;
    if (!this.state.user && history) {
      history.push('/sign-in');
    } else {
      ConfigureHTTPHeaders();
    }

    window.addEventListener('storage', e => {
      if (e.url.split('/')[3] === 'sign-out' && history) {
        history.push('/sign-in');
      } else if (e.url.split('/')[3] === 'sign-in' && history) {
        history.push('/');
      }
    });
  }
}

export default ProtectedComponent;
