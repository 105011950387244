import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/rideset.svg';
import RideSetNumber from './RideSetNumber';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        HAS APP
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'roadshow_id',
  accessor: row => `${row.roadshow_id}`,
  resizable: false,
  Cell: ({ row }) => <RideSetNumber value={row._original.roadshow_id} />
};
