import React from 'react';
import ProtectedComponent from '../components/ProtectedComponent';
import ReservationNav from './ReservationNav';
import './ReservationNav.scss';
import ReactGA from 'react-ga';

class Reservations extends ProtectedComponent {
  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="content-above-footer">
        <ReservationNav />
      </div>
    );
  }
}

export default Reservations;
