import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import TextInputField from '../components/TextInputField';
import LoadingButton from '../components/LoadingButton';
import './SignIn.scss';
import ReactGA from 'react-ga';

const initialState = {
  email: '',
  name: '',
  password: '',
  errors: {
    email: '',
    password: '',
    login: ''
  },
  querying: false
};

const isLoginDataValid = state => state.email && state.password;

const isEmailTyped = state => state.email.length === 0;
const isPasswordTyped = state => state.password.length === 0;

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.signIn = this.signIn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  signIn(e) {
    e.preventDefault();
    this.setState({ querying: true, errors: { email: '', password: '', login: '' } });
    axios
      .post(process.env.REACT_APP_GD2_URL + '/portal_apis/clients/account/login.json', {
        email: this.state.email,
        password: this.state.password
      })
      .then(payload => {
        this.setState({ querying: false });
        let userData = {
          accessIdentifier: payload.data.access_identifier,
          accessKey: payload.data.access_key,
          accessKeyIndex: payload.data.access_key_index,
          email: this.state.email,
          name: payload.data.name
        };

        localStorage.setItem('user', JSON.stringify(userData));
        this.props.history.push('/');
      })
      .catch(err => {
        console.error(err);

        let message = 'Sorry, an error occurred. Please try again.';
        if (err.response && [401, 403].includes(err.response.status)) {
          message = 'Invalid credentials.';
        }
        this.setState({ querying: false, errors: { email: '', password: '', login: message } });
      });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, errors: { ...this.state.errors, [name]: '' } });
  }

  handleBlur(e) {
    const { value, name } = e.target;
    if (!value) {
      this.setState({ errors: { ...this.state.errors, [name]: 'This field is required.' } });
    }
  }

  render() {
    const { querying, errors, email, password } = this.state;
    const enabledUnderEmail = isEmailTyped(this.state);
    const enabledUnderPassword = isPasswordTyped(this.state);
    const enabled = isLoginDataValid(this.state) && !querying;

    return (
      <div className="sign-in content-above-footer">
        <div className="main-content">
          <div className="page-header u-center-text">Sign In</div>
          <div className="card">
            <form onSubmit={this.signIn}>
              <TextInputField
                name="email"
                label="USERNAME"
                type="text"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={email}
                required={true}
                showUnderline={enabledUnderEmail}
                error={errors.email}
              />

              <TextInputField
                name="password"
                label="PASSWORD"
                type="password"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={password}
                required={true}
                showUnderline={enabledUnderPassword}
                error={errors.password}
                autocomplete="off"
              />

              <LoadingButton title="Sign In" enabled={enabled} animating={querying} onClick={this.signIn} />
              <div className="u-margin-bottom-l" />
              {errors.login && (
                <div className="error">
                  <label>{errors.login}</label>
                </div>
              )}

              <Link to="/forgot-password" className="forgot-password">
                Forgot Password?
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default SignIn;
