import React from 'react';
import './column.scss';
import icon from '../table_icons/status.svg';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        INVITE
      </div>
    </div>
  ),
  accessor: 'vehicle_class_name',
  resizable: false
};
