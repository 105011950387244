import React, { Component } from 'react';
import './CollapsableText.scss';

export default class collapsableText extends Component {
  constructor(props) {
    super(props);
    this.state = { closed: true, longText: false };
  }

  onClick = () => this.setState({ closed: !this.state.closed });

  closedText = () => {
    const { text, fadeType } = this.props;
    const gradientClass = fadeType === 'rideNote' ? 'text-gradient--rideNote' : '';
    return (
      <div ref="text" className="text-content-collapsed" onClick={this.onClick}>
        {text}
        <div className={`text-gradient ${gradientClass}`} />
      </div>
    );
  };

  openedText = () => {
    const { text } = this.props;
    return (
      <div ref="text" onClick={this.onClick}>
        {text}
      </div>
    );
  };

  componentDidMount() {
    const { text } = this.props;
    /* 68px is the height of 4 lines on lines using text-gradient*/
    if (text && parseInt(window.getComputedStyle(this.refs.text).height) > parseInt('68px')) {
      this.setState({ longText: true });
    }
  }

  render() {
    const { text } = this.props;
    const { closed, longText } = this.state;
    if (!text) return null;
    if (longText) {
      return closed ? this.closedText() : this.openedText();
    }
    return <div ref="text">{text}</div>;
  }
}
