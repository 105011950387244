import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedComponent from '../components/ProtectedComponent';
import './RequestApp.scss';
import right from '../components/table_components/table_pagination/right.svg';
import principalPhone from '../components/images/apps/principal@2x.png';
import assistantPhone from '../components/images/apps/assistant@2x.png';
import pilotPhone from '../components/images/apps/pilot@2x.png';
import ReactGA from 'react-ga';

class Dashboard extends ProtectedComponent {
  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="request-apps">
        <div className="main-content">
          <div>
            <div className="header">Manage your mobile app access</div>
            <div className="help">
              Our mobile apps help you get the most out of Fortis journeys. Invite your fellow assistants, pilots, and
              principals.
            </div>
          </div>
          <div className="cards">
            <Link to="/apps/principal" className="card">
              <div className="card-header">Fortis Principal app</div>
              <div className="invite">
                INVITE A PRINCIPAL
                <img src={right} alt="" className="arrow" />
              </div>
              <div className="phone">
                <img src={principalPhone} alt="" className="phone-image" />
              </div>
            </Link>
            <Link to="/apps/assistant" className="card">
              <div className="card-header">Fortis Assistant app</div>
              <div className="invite">
                INVITE AN ASSISTANT
                <img src={right} alt="" className="arrow" />
              </div>
              <div className="phone">
                <img src={assistantPhone} alt="" className="phone-image" />
              </div>
            </Link>
            <Link to="/apps/pilot" className="card">
              <div className="card-header">Fortis Pilot app</div>
              <div className="invite">
                INVITE A PILOT
                <img src={right} alt="" className="arrow" />
              </div>
              <div className="phone">
                <img src={pilotPhone} alt="" className="phone-image" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
