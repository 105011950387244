import React from 'react';
import ProtectedComponent from '../components/ProtectedComponent';
import PickupHeader from './pickupHeader';
import PickupForm from './pickupForm';
import RuleOfThumb from './ruleOfThumb';
import PickupCalculatorInfo from './pickupCalcInfo';
import FRMap from '../components/map_components/Map';
import { GoogleApiWrapper } from 'google-maps-react';
import Geocoder from 'react-native-geocoding';
import timezone from 'google-timezone-api';
import update from 'immutability-helper';
import moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import './pickupCalc.scss';
import ReactGA from 'react-ga';

Geocoder.init('AIzaSyAbn7ppWMwyvB9zeLEKuWLXaFEef16_Ehs');

const initialState = {
  pickup: {
    index: '1',
    name: 'pickup',
    label: 'PICKUP LOCATION',
    value: '',
    error: null
  },
  pickupCordinates: null,
  destination: {
    index: '2',
    name: 'destination',
    label: 'DESTINATION',
    value: '',
    error: ''
  },
  destinationCordinates: null,
  arrival: {
    index: '',
    name: 'arrival',
    label: 'DESIRED ARRIVAL TIME',
    value: '',
    error: ''
  },
  arrivalInfo: [],
  pickupTime: '',
  driveTime: '',
  distance: '',
  submit: false,
  finish: false,
  pickupTimezone: null,
  destinationTimezone: null
};

class PickupCalculator extends ProtectedComponent {
  constructor(props) {
    super(props);
    this.state = update(this.state, { $merge: initialState });
  }

  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleChange = e => {
    var { name, value } = e.target;
    var data = [];
    var timeregex = /([0-9]|:| |[AaPp]|[Mm])/;
    var goodValue = false;
    var oldValue = '';

    if (name === 'pickup') {
      data = update(this.state.pickup, { value: { $set: value } });
    } else if (name === 'destination') {
      data = update(this.state.destination, { value: { $set: value } });
    } else if (name === 'arrival') {
      if (value.length <= 8 && value.length > 0) {
        if (value.charAt(value.length - 1).match(timeregex) !== null) {
          data = update(this.state.arrival, { value: { $set: value } });
          goodValue = true;
        }
      }
      if (goodValue === false) {
        if (value.length === 0) {
          data = update(this.state.arrival, { value: { $set: oldValue } });
        } else {
          for (var i = 0; i < value.length - 1; i++) {
            oldValue = oldValue + value.charAt(i);
          }
          data = update(this.state.arrival, { value: { $set: oldValue } });
        }
      }
    }
    this.setState({ [name]: data });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submit: true, querying: true });
    this.locationFinder(this.state.pickup.value, 'pickup');
  };

  locationFinder = (e, type) => {
    if (!e) {
      e = this.state.destination.value;
    }
    Geocoder.from(e)
      .then(json => {
        if (json.results.length > 0) {
          var location = json.results[0].geometry.location;
          var error;
          if (type === 'pickup') {
            error = update(this.state.pickup, { error: { $set: null } });
            this.setState({ pickupCordinates: location, pickup: error }, this.locationFinder);
          } else {
            error = update(this.state.destination, { error: { $set: null } });
            this.setState({ destinationCordinates: location, destination: error }, this.distanceCalc);
          }
        }
      })
      .catch(error => {
        if (type === 'pickup') {
          error = update(this.state.pickup, {
            error: { $set: 'Unknown Location' }
          });
          this.setState(
            {
              pickup: error,
              pickupTime: '',
              driveTime: '',
              distance: ''
            },
            this.locationFinder
          );
        } else {
          error = update(this.state.destination, {
            error: { $set: 'Unknown Location' }
          });
          this.setState(
            {
              destination: error,
              pickupTime: '',
              driveTime: '',
              distance: ''
            },
            this.timeCalc
          );
        }
      });
  };

  distanceCalc = () => {
    var service = new this.props.google.maps.DistanceMatrixService();
    var component = this;
    service.getDistanceMatrix(
      {
        origins: [component.state.pickupCordinates],
        destinations: [component.state.destinationCordinates],
        travelMode: 'DRIVING',
        unitSystem: this.props.google.maps.UnitSystem.IMPERIAL
      },
      function(response, status) {
        if (status !== 'OK') {
          console.warn(response);
        } else {
          const data = response.rows[0].elements;
          if (data[0].duration) {
            component.setState(
              {
                driveTime: data[0].duration.text,
                distance: data[0].distance.text
              },
              component.timezoneCalc
            );
          }
        }
      }
    );
  };

  timezoneCalc = () => {
    var component = this;
    var lat, lng;
    if (!this.state.pickupTimezone) {
      lat = this.state.pickupCordinates.lat;
      lng = this.state.pickupCordinates.lng;
    } else {
      lat = this.state.destinationCordinates.lat;
      lng = this.state.destinationCordinates.lng;
    }
    timezone({
      location: lat + ',' + lng,
      key: 'AIzaSyAbn7ppWMwyvB9zeLEKuWLXaFEef16_Ehs'
    })
      .then(function(result) {
        if (!component.state.pickupTimezone) {
          component.setState({ pickupTimezone: result.timeZoneId }, component.timezoneCalc);
        } else {
          component.setState({ destinationTimezone: result.timeZoneId }, component.timeCalc);
        }
      })
      .catch(function(err) {
        console.error(err);
      });
  };

  getTimezoneOffset = timezone => {
    Number(
      moment()
        .tz(timezone)
        .format('Z')
        .split(':')[0]
    );
  };

  timeCalc = () => {
    var arrival = this.state.arrival.value;
    var driveTime = this.state.driveTime;
    var timeStr = driveTime.split(' ');
    var hourStr, minStr, error, destinationOffset, pickupOffset, pickupTZ, momentObj;

    if (timeStr[1] === 'hours' || timeStr[1] === 'hour') {
      hourStr = timeStr[0];
      minStr = timeStr[2];
    } else {
      minStr = timeStr[0];
    }

    if (driveTime === '') {
      momentObj = moment(arrival, ['hh:mm A']);
    } else {
      destinationOffset = this.getTimezoneOffset(this.state.destinationTimezone);
      pickupOffset = this.getTimezoneOffset(this.state.pickupTimezone);
      pickupTZ = moment()
        .tz(this.state.pickupTimezone)
        .format('z');
      momentObj = moment(arrival, ['hh:mm A'])
        .subtract({ hours: hourStr, minutes: minStr })
        .subtract({ hours: pickupOffset - destinationOffset })
        .format('hh:mm A z');
    }

    if (String(momentObj) === 'Invalid date') {
      error = update(this.state.arrival, { error: { $set: 'Invalid Time' } });
      this.setState({
        arrival: error,
        pickupTime: '',
        driveTime: '',
        distance: '',
        querying: false
      });
    } else {
      error = update(this.state.arrival, { error: { $set: null } });
      if (this.state.pickup.error === null && this.state.destination.error === null) {
        this.setState({
          pickupTime: String(momentObj) + pickupTZ,
          finish: true,
          arrival: error,
          querying: false
        });
      }
    }
  };

  displayMap() {
    return (
      <div className="map-container">
        <FRMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?client=gme-fortisridersacquisition&v=3&libraries=places&language=en"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          markers={[
            {
              lat: this.state.pickupCordinates.lat,
              lng: this.state.pickupCordinates.lng
            },
            {
              lat: this.state.destinationCordinates.lat,
              lng: this.state.destinationCordinates.lng
            }
          ]}
          chauffeurPosition={{}}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="pickup-page content-above-footer">
        <div className="main-content">
          <PickupHeader />
          <div className="row-calc">
            <div className={this.state.finish ? 'card calc' : 'card '}>
              <PickupForm
                pickup={this.state.pickup}
                destination={this.state.destination}
                arrival={this.state.arrival}
                querying={this.state.querying}
                onChange={this.handleChange}
                handleSubmit={this.handleSubmit}
              />
              <div className={this.state.finish ? 'right' : ''}>
                <PickupCalculatorInfo
                  finish={this.state.finish}
                  pickupTime={this.state.pickupTime}
                  destination={this.state.destination}
                  distance={this.state.distance}
                  driveTime={this.state.driveTime}
                />
              </div>
            </div>
            <RuleOfThumb />
          </div>
        </div>
        {this.state.finish ? this.displayMap() : null}
      </div>
    );
  }
}

const LoadingContainer = () => (
  <div className="page-loading-indicator-container content-above-footer">
    <div className="circle-loader circle-loader--large" />
  </div>
);

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAbn7ppWMwyvB9zeLEKuWLXaFEef16_Ehs',
  LoadingContainer: LoadingContainer
})(PickupCalculator);
