import React, { Component } from 'react';
import CollapsableText from '../../components/CollapsableText';

export default class waypointNote extends Component {
  render() {
    let { note, header } = this.props;

    if (!note) return null;
    return (
      <div className="notes">
        <div className="notes-header">{header}</div>
        <CollapsableText text={note} />
      </div>
    );
  }
}

waypointNote.defaultProps = {
  header: 'NOTES'
};
