import React from 'react';
import logo from '../images/logo-fortis.png';
import RightNav from './rightNav';
import './FRHeader.scss';
import { useLocation } from 'react-router-dom';

function DisplayRightNav() {
  let location = useLocation();
  if (location.pathname !== '/sign-in') {
    return true;
  }
}

function DisplayGradient() {
  let location = useLocation();
  if (location.pathname !== '/') {
    return <div className="header-gradient" />;
  }
}

export default function FRHeader() {
  return (
    <div>
      <div className="header-nav">
        <div className="left-nav">
          <a href="/">
            <img className="logo" src={logo} alt="Fortis Logo" />
          </a>
        </div>
        {DisplayRightNav() && <RightNav />}
      </div>
      {DisplayGradient()}
    </div>
  );
}
