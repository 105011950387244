import React, { Component } from 'react';
import axios from 'axios';
import TextInputField from '../components/TextInputField';
import LoadingButton from '../components/LoadingButton';
import Errors from '../components/Errors.js';
import './Password.scss';
import '../components/TextInputField.scss';

const initialState = {
  new_password: '',
  confirm_password: '',
  errors: null,
  querying: false
};

const isLoginDataValid = state => state.confirm_password.length > 0 && state.confirm_password === state.new_password;

const isNewPasswordTyped = state => state.new_password.length === 0;
const isConfirmPasswordTyped = state => state.confirm_password.length === 0;

export default class resetPassword extends Component {
  state = initialState;

  resetPassword = e => {
    const self = this;

    this.setState({ querying: true });
    e.preventDefault();
    axios
      .post(process.env.REACT_APP_GD2_URL + '/portal_apis/clients/account/reset_password.json', {
        reset_password_token: window.location.href.split('=')[1],
        password: this.state.new_password,
        password_confirmation: this.state.confirm_password
      })
      .then(() => {
        self.props.history.push('/sign-in');
      })
      .catch(err => {
        console.error(err);
        let errorMessages;
        if (err.response && [400, 422].includes(err.response.status)) {
          errorMessages = Object.values(err.response.data.errors).map(val => val.detail);
          for (let i = 0; i < errorMessages.length; i++) {
            if (errorMessages[i] === "Parameter 'reset_password_token' is required.") {
              errorMessages[i] = 'Invalid password reset link.';
            } else if (err.response.data.error && err.response.data.error.message) {
              errorMessages = [err.response.data.error.message];
            }
          }
        }
        if (errorMessages === undefined) {
          errorMessages = ['An unexpected error occured please try again.'];
        }
        this.setState({ errors: errorMessages, querying: false });
      });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const enabled = isLoginDataValid(this.state) && !this.state.querying;
    const enabledUnderNewPassword = isNewPasswordTyped(this.state);
    const enabledUnderConfirmPassword = isConfirmPasswordTyped(this.state);
    const errors = this.state.errors;

    return (
      <div className="password-page content-above-footer">
        <div className="page-header u-center-text">Reset Password</div>
        <div>
          <div className="password--card">
            <form onSubmit={this.resetPassword} className={errors ? 'u-margin-bottom-l' : ''}>
              <TextInputField
                name="new_password"
                label="NEW PASSWORD"
                type="password"
                onChange={this.handleChange}
                value={this.state.new_password}
                required={true}
                showUnderline={enabledUnderNewPassword}
                autocomplete="off"
              />
              <TextInputField
                name="confirm_password"
                label="CONFIRM NEW PASSWORD"
                type="password"
                onChange={this.handleChange}
                value={this.state.confirm_password}
                required={true}
                showUnderline={enabledUnderConfirmPassword}
                autocomplete="off"
              />
              <LoadingButton
                title="Reset Password"
                enabled={enabled}
                animating={this.state.querying}
                onClick={this.resetPassword}
              />
            </form>
            {errors ? <Errors errors={errors} /> : null}
          </div>
        </div>
      </div>
    );
  }
}
