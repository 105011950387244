import React, { Component } from 'react';
import './InvoiceRequestButton.scss';

export default class InvoiceRequestButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: false };
  }

  togglePopup = e => {
    e.preventDefault();
    this.props.targetRef.setState({
      requestedInvoice: true,
      requestedInvoiceId: this.props.invoiceId,
      requestedInvoiceNumber: this.props.invoiceNumber,
      requestedInvoiceUrl: this.props.url
    });
  };

  render() {
    return (
      <div>
        <span className="button button--secondary" onClick={this.togglePopup.bind(this)}>
          Request PDF Invoice
        </span>
      </div>
    );
  }
}
