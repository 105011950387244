import React, { Component } from 'react';
import axios from 'axios';
import TextInputField from '../components/TextInputField';
import LoadingButton from '../components/LoadingButton';
import './SignIn.scss';
import '../components/TextInputField.scss';

const initialState = {
  email: '',
  password: '',
  querying: false
};

const isLoginDataValid = state => state.email.length > 5;

const isEmailTyped = state => state.email.length === 0;
const isPasswordTyped = state => state.password.length === 0;

export default class AcceptUser extends Component {
  state = initialState;

  acceptUser = e => {
    const self = this;
    this.setState({ querying: true });

    e.preventDefault();
    axios
      .post(process.env.REACT_APP_GD2_URL + '/portal_apis/clients/invitation/accept_user.json', {
        email: this.state.email,
        password: this.state.password,
        invitation_token: window.location.href.split('=')[1]
      })
      .then(() => {
        self.props.history.push('/sign-in');
      })
      .catch(err => {
        console.error(err);
        this.setState({ querying: false });
      });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const enabled = isLoginDataValid(this.state) && !this.state.querying;
    const enabledUnderEmail = isEmailTyped(this.state);
    const enabledUnderPassword = isPasswordTyped(this.state);

    return (
      <div className="password-page content-above-footer">
        <div className="main-content">
          <div className="page-header u-center-text">Create an Account</div>
          <div>
            <div className="password--card">
              <form onSubmit={this.acceptUser} className="input-field-suggestion-copy">
                <TextInputField
                  name="email"
                  label="EMAIL"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.email}
                  required={true}
                  showUnderline={enabledUnderEmail}
                />
                <TextInputField
                  name="password"
                  label="PASSWORD (Minimum of 8 Characters)"
                  type="password"
                  onChange={this.handleChange}
                  value={this.state.password}
                  required={true}
                  showUnderline={enabledUnderPassword}
                  autocomplete="off"
                />
                <LoadingButton
                  title="Create Account"
                  enabled={enabled}
                  animating={this.state.querying}
                  onClick={this.acceptUser}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
