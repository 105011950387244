import React, { Component } from 'react';

export default class Errors extends Component {
  render() {
    const { errors } = this.props;
    return (
      <ul className="error">
        {errors.map((error, index) => (
          <li className="u-margin-bottom-xs" key={index}>
            {error}
          </li>
        ))}
      </ul>
    );
  }
}
