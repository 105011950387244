import React, { Component } from 'react';
import RideNote from './rideNote';
import RideOverview from './ride_overview/rideOverview';
import RideItinerary from './ride_itinerary/rideItinerary';
import './rideView.scss';

export default class RideDetails extends Component {
  render() {
    const { show, reservation } = this.props;
    if (!show) return null;
    return (
      <div className="ride-content-left">
        <RideNote note={reservation.notes} />
        <div className="ride-info">
          <RideOverview reservation={reservation} />
          <RideItinerary reservation={reservation} />
        </div>
      </div>
    );
  }
}
