import axios from 'axios';

class ApiWrapper {
  request(options, successCallback, errorCallback, finallyCallback) {
    axios({
      method: options.httpVerb,
      url: options.url,
      data: options.data,
      params: options.queryParams
    })
      .then(json => {
        if (successCallback) {
          successCallback(json.data);
        } else {
          console.warn('argument successCallback should be passed');
          console.log('[data]', json.data);
        }
      })
      .catch(error => {
        if (errorCallback) {
          errorCallback(error);
        } else {
          console.warn('argument errorCallback should be passed');
          console.error('[error]', error);
        }
      })
      .finally(() => {
        if (finallyCallback) {
          finallyCallback();
        }
      });
  }
}

class Api {
  delete(url, successCallback, errorCallback, finallyCallback) {
    let api = new ApiWrapper();
    api.request(
      {
        httpVerb: 'delete',
        url: url
      },
      successCallback,
      errorCallback,
      finallyCallback
    );
  }

  get(url, successCallback, errorCallback, finallyCallback) {
    let api = new ApiWrapper();
    api.request(
      {
        httpVerb: 'get',
        url: url,
        data: null,
        queryParams: null
      },
      successCallback,
      errorCallback,
      finallyCallback
    );
  }

  patch(url, requestData, successCallback, errorCallback, finallyCallback) {
    let api = new ApiWrapper();
    api.request(
      {
        httpVerb: 'patch',
        url: url,
        data: requestData,
        queryParams: null
      },
      successCallback,
      errorCallback,
      finallyCallback
    );
  }

  post(url, requestData, successCallback, errorCallback, finallyCallback) {
    let api = new ApiWrapper();
    api.request(
      {
        httpVerb: 'post',
        url: url,
        data: requestData,
        queryParams: null
      },
      successCallback,
      errorCallback,
      finallyCallback
    );
  }
}

export default Api;
