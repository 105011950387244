import React from 'react';
import PropTypes from 'prop-types';
import './TextInputField.scss';
import EyeClosed from '../components/images/eye-closed.svg';
import EyeOpened from '../components/images/eye-open.svg';
import TextareaAutosize from 'react-autosize-textarea';

const initialType = {
  type: 'password'
};

export default class TextInputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialType;
    this.determineClass = this.determineClass.bind(this);
    this.displayPasswordIcon = this.displayPasswordIcon.bind(this);
    this.displayInput = this.displayInput.bind(this);
  }

  handleClick = () => {
    if (this.state.type === 'password') {
      this.setState({ type: 'text' });
    } else {
      this.setState({ type: 'password' });
    }
  };

  determineClass = () => {
    const { type, showUnderline } = this.props;
    let cssClasses = [];

    if (type === 'password') {
      cssClasses.push('js-test-password');
    }
    if (showUnderline) {
      cssClasses.push('underline');
    }
    return cssClasses.join(' ');
  };

  displayPasswordIcon = () => {
    const { type, showUnderline } = this.props;
    const passwordEye = this.state.type === 'password' ? EyeOpened : EyeClosed;
    const passwordReveal = type === 'password' ? passwordEye : '';

    if (showUnderline) {
      return null;
    } else {
      return (
        <div className="passwordIconContainer" onClick={() => this.handleClick()}>
          <img src={passwordReveal} alt="" />
        </div>
      );
    }
  };

  displayInput = () => {
    const { autocomplete, multiline, name, onChange, onBlur, placeholder, required, type, value } = this.props;

    if (multiline) {
      return (
        <TextareaAutosize
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className="underline"
          placeholder={placeholder}
          required={required}
        />
      );
    } else {
      return (
        <input
          id={name}
          className={this.determineClass()}
          type={type === 'text' ? type : this.state.type}
          name={name}
          value={value}
          ref={input => (this.email = input)}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={autocomplete}
          placeholder={placeholder}
          required={required}
        />
      );
    }
  };

  render() {
    const { error, label } = this.props;

    return (
      <div className="input-lockup">
        <label>{label}</label>
        {this.displayInput()}
        {this.displayPasswordIcon()}
        {error ? <div className="error error--under-input">{error}</div> : null}
      </div>
    );
  }
}

TextInputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  showUnderline: PropTypes.bool.isRequired,
  placeholder: PropTypes.string
};

TextInputField.defaultProps = {
  autoComplete: 'on',
  multiline: false,
  required: false,
  showUnderline: true,
  value: ''
};
