import React from 'react';

function ButtonRow(props) {
  const { action, cancelText = 'Cancel', disabled, onClickCancel, onClickSuccess, successText } = props;
  return (
    <div className="button-row">
      <button className="button-text-only" onClick={onClickCancel} type="button" value="cancel">
        {cancelText}
      </button>
      <button disabled={disabled} onClick={onClickSuccess} type="button" value={action}>
        {successText}
      </button>
    </div>
  );
}

export default ButtonRow;
