import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/status.svg';
import FormatStatus from './formatStatus';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        STATUS
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'reservation_status_name',
  width: 250,
  accessor: row => row.portal_status,
  resizable: false,
  Cell: ({ row }) => <FormatStatus value={row._original.portal_status} />
};
