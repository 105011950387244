import Api from './api';

class CreditCardsApi {
  mapCreditCardsFromGD2(gd2CreditCards) {
    return gd2CreditCards.map(gd2cc => {
      return this.mapCreditCardFromGD2(gd2cc);
    });
  }

  mapCreditCardFromGD2(gd2cc) {
    return {
      id: gd2cc.id,
      expirationMonth: gd2cc.expiration_month,
      expirationYear: gd2cc.expiration_year,
      twoDigitYear: gd2cc.expiration_year.toString().slice(-2),
      lastFour: gd2cc.last_four,
      cardTypeCode: gd2cc.card_type_code,
      cardTypeShortName: gd2cc.card_type_code.substr(0, 4),
      cardTypeName: gd2cc.card_type_name,
      clientId: gd2cc.client_id,
      clientName: gd2cc.client_name,
      cardholderName: gd2cc.cardholder_name,
      cardholderPostalCode: gd2cc.cardholder_postal_code,
      createdAt: gd2cc.created_at,
      deletedAt: gd2cc.deleted_at,
      notes: gd2cc.external_notes || '',
      expired: gd2cc.expired
    };
  }

  index(successCallback, errorCallback, finallyCallback) {
    let api = new Api();
    api.get(
      process.env.REACT_APP_GD2_URL + `/mobile_apis/client_booking/credit_cards.json`,
      data => {
        successCallback(this.mapCreditCardsFromGD2(data.credit_cards));
      },
      errorCallback,
      finallyCallback
    );
  }

  create(token, formData, successCallback, errorCallback) {
    const { card } = token;
    const requestData = {
      credit_card: {
        cardholder_name: formData.cardholder,
        cardholder_postal_code: card.address_zip,
        expiration_month: card.exp_month,
        expiration_year: card.exp_year,
        client_id: formData.clientId,
        last_four: card.last4,
        stripe_token: token.id,
        stripe_card_id: card.id,
        credit_card_type_name: card.brand,
        external_notes: formData.notes
      }
    };

    let api = new Api();
    api.post(
      process.env.REACT_APP_GD2_URL + `/mobile_apis/client_booking/credit_cards`,
      requestData,
      data => {
        successCallback(this.mapCreditCardFromGD2(data.credit_card));
      },
      errorCallback
    );
  }

  delete(creditCard, successCallback, errorCallback) {
    let api = new Api();
    api.delete(
      process.env.REACT_APP_GD2_URL + `/mobile_apis/client_booking/credit_cards/${creditCard.id}`,
      data => {
        successCallback(data);
      },
      errorCallback
    );
  }

  edit(formData, successCallback, errorCallback) {
    const creditCard = {
        id: formData.id,
        cardholder_name: formData.cardholder,
        expiration_month: formData.expirationMonth,
        expiration_year: formData.expirationYear,
        external_notes: formData.notes
      },
      requestData = {
        credit_card: creditCard
      };

    let api = new Api();
    api.patch(
      process.env.REACT_APP_GD2_URL + `/mobile_apis/client_booking/credit_cards/${creditCard.id}`,
      requestData,
      data => {
        successCallback(data);
      },
      errorCallback
    );
  }
}

export default CreditCardsApi;
