import UserWelcome from './UserWelcome';
import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedComponent from '../ProtectedComponent';

class RightNav extends ProtectedComponent {
  render() {
    if (!this.state.user) {
      return null;
    }

    const currentLocation = window.location.pathname;
    return (
      <ul className="right-nav">
        <li>
          <Link to="/">Dashboard</Link>
          <div className={'/' === currentLocation ? 'current-page-active' : 'current-page'} />
        </li>
        <li>
          <Link to="/reservations">Rides</Link>
          <div className={'/reservations' === currentLocation ? 'current-page-active' : 'current-page'} />
        </li>
        <li>
          <Link to="/invoices">Invoices</Link>
          <div className={'/invoices' === currentLocation ? 'current-page-active' : 'current-page'} />
        </li>
        {/* <li>
          <Link to="/apps">Request App Access</Link>
          <div className={'/apps' === currentLocation ? 'current-page' : null} />
        </li> */}
        <li>
          <Link to="/pick-up-calculator">Pickup Calculator</Link>
          <div className={'/pick-up-calculator' === currentLocation ? 'current-page-active' : 'current-page'} />
        </li>
        <UserWelcome />
      </ul>
    );
  }
}

export default RightNav;
