import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './incorrectURL.scss';

export default class IncorrectURL extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      url: '/'
    };
  }

  toDashboard = e => {
    this.setState({ redirect: true });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.url} />;
    }

    return (
      <div className="redirect--page">
        <div className="redirect--title">{this.props.title}</div>
        <div className="message">{this.props.message}</div>
        <form onSubmit={this.toDashboard}>
          <button className="button" type="submit" value="View Dashboard">
            View Dashboard
          </button>
        </form>
      </div>
    );
  }
}

IncorrectURL.defaultProps = {
  title: 'Page Not Found',
  message: 'You may be trying to access a page that is not available.'
};
