import React from 'react';
import './column.scss';
import sort from '../table_icons/sort.svg';
import icon from '../table_icons/chauffeur.svg';
import FormatChauffeur from './formatChauffeur';

export default {
  Header: () => (
    <div className="column-header">
      <div className="ride">
        <img src={icon} alt="" className="icon4table" />
        CHAUFFEUR
        <img src={sort} alt="" className="sorter" />
      </div>
    </div>
  ),
  id: 'chauffeur',
  accessor: row => row.chauffeur && row.chauffeur.first_name,
  resizable: false,
  Cell: ({ row }) => (
    <FormatChauffeur value={row._original.chauffeur} favorited={row._original.passenger_preferred_chauffeur} />
  )
};
