import React, { Component } from 'react';
import { ReactComponent as ArrowDown } from '../Icons/arrow-down.svg';
import { ReactComponent as DeleteIcon } from '../Icons/icon-delete.svg';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './ActionCard.scss';
import TextInputField from '../components/TextInputField';
import ButtonRow from '../credit_cards/ButtonRow';
import ClientsApi from '../api/clients';
import CreditCardsApi from '../api/creditCards';

const initialState = {
  clientId: '',
  clients: [],
  cardholder: '',
  errors: [],
  expirationMonth: '',
  expirationYear: '',
  notes: '',
  querying: false
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default class ActionCard extends Component {
  constructor(props) {
    super(props);

    this.closeCard = React.createRef();
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.displayHeader = this.displayHeader.bind(this);
    this.displayBody = this.displayBody.bind(this);
    this.successHeader = this.successHeader.bind(this);
    this.successBody = this.successBody.bind(this);
  }

  componentDidMount() {
    const { action } = this.props;
    if (action === 'add') {
      this.fetchClients();
    } else if (action === 'edit') {
      this.updateStateCardInfo(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    const prevCreditCard = prevProps.creditCard;

    if (this.props.action !== prevProps.action) {
      this.updateStateCardInfo(this.props);
    } else if (this.props.action === 'edit' && prevCreditCard) {
      const { creditCard } = this.props;
      if (creditCard.id !== prevProps.creditCard.id) {
        this.updateStateCardInfo(this.props);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateStateCardInfo(props) {
    const { creditCard = {} } = props,
      { cardholderName: cardholder, clientId, expirationMonth, twoDigitYear, id, notes } = creditCard;

    this.setState({
      cardholder: cardholder,
      clientId: clientId,
      expirationMonth: expirationMonth,
      expirationYear: twoDigitYear,
      id: id,
      notes: notes
    });
  }

  fetchClients() {
    let api = new ClientsApi();
    api.index(data => {
      this.setState({
        clients: data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
      });
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleBlur(e) {
    const { value, name } = e.target;
    if (!value) {
      this.setState({ errors: { ...this.state.errors, [name]: 'This field is required.' } });
    }
  }

  handleClick = async (e, elements, stripe) => {
    e.preventDefault();

    const { value } = e.target;

    switch (value) {
      case 'add':
        this.handleGetStripeToken(e, elements, stripe, this.addCardToGd2, errors => this.setState({ errors: errors }));
        break;
      case 'cancel':
      case 'close':
        const { onClose } = this.props;
        if (onClose) {
          onClose(e);
        }
        break;
      case 'delete':
        this.deleteCard();
        break;
      case 'edit':
        this.editCard();
        break;
      default:
        console.error(`value '${value}' is not supported!`);
    }
  };

  editCard = async () => {
    const { action, creditCard, onSuccess } = this.props,
      { cardholder, expirationMonth, expirationYear, notes } = this.state;

    const formData = {
      id: creditCard.id,
      cardholder: cardholder,
      expirationMonth: expirationMonth,
      expirationYear: `20${expirationYear}`,
      notes: notes
    };

    let api = new CreditCardsApi();
    api.edit(
      formData,
      () => {
        onSuccess({ action: action, success: true });
        this.resetInputs();
      },
      errors => this.setState({ errors: errors })
    );
  };

  deleteCard = async () => {
    const { action, creditCard, onSuccess } = this.props;

    let api = new CreditCardsApi();
    api.delete(
      creditCard,
      () => {
        onSuccess({ action: action, success: true });
        this.resetInputs();
      },
      errors => this.setState({ errors: errors })
    );
  };

  resetInputs = () => {
    this.interval = setInterval(() => this.closeCard.current.click(), 3000);

    this.setState({
      clientId: '',
      cardholder: '',
      errors: [],
      expirationMonth: '',
      expirationYear: '',
      notes: ''
    });
  };

  addCardToGd2 = async token => {
    const { action, onSuccess } = this.props;
    const { clientId, cardholder, notes } = this.state,
      ccData = { clientId: clientId, cardholder: cardholder, notes: notes };

    let api = new CreditCardsApi();
    api.create(token, ccData, data => {
      onSuccess({ action: action, success: true });
      this.resetInputs();
    });
  };

  handleGetStripeToken = async (e, elements, stripe, successCallback, errorCallback) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.warn('Stripe not loaded yet');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (token) {
      if (successCallback) {
        successCallback(token);
      } else {
        console.error('argument successCallback must be passed');
        console.log('[token]', token);
        errorCallback({ error: 'argument successCallback must be passed' });
      }
      return;
    }

    if (error) {
      console.error('[error]', error);
      if (errorCallback) {
        errorCallback(error);
      }
    }
  };

  successHeader = () => {
    const { action } = this.props;

    if (action === 'add') {
      return 'Success!';
    } else if (action === 'edit') {
      return 'Updated!';
    } else if (action === 'delete') {
      return 'Done!';
    }
  };

  successBody = () => {
    const { action } = this.props;

    if (action === 'add') {
      return 'Your card has been successfully added.';
    } else if (action === 'edit') {
      return 'Your card details have been successfully saved.';
    } else if (action === 'delete') {
      return 'Your card has been successfully deleted.';
    }
  };

  setCardTypeBackground(creditCard) {
    return `label label--small label--${creditCard.cardTypeCode.toLowerCase()}`;
  }

  actionHeaderText = () => {
    const { action, creditCard } = this.props;

    if (action === 'add') {
      return 'Add a Credit Card';
    } else if (action === 'edit') {
      return (
        <React.Fragment>
          Edit <div className={`${this.setCardTypeBackground(creditCard)}`}>{creditCard.cardTypeShortName}</div>
          <span className="u-margin-right-s">• • • •</span>
          {creditCard.lastFour}
        </React.Fragment>
      );
    } else if (action === 'delete') {
      return (
        <React.Fragment>
          Are you sure you want to delete{' '}
          <div className={`${this.setCardTypeBackground(creditCard)}`}>{creditCard.cardTypeShortName}</div>
          <span className="u-margin-right-s">• • • •</span>
          {creditCard.lastFour}?
        </React.Fragment>
      );
    }
  };

  displayHeader = () => {
    if (this.props.success) {
      return <div className="u-center-text">{this.successHeader()}</div>;
    } else {
      return <div>{this.actionHeaderText()}</div>;
    }
  };

  renderClientDetails(action) {
    if (action === 'add') {
      return this.renderSelectContainer();
    }

    return <div className="saved-and-disabled-text">{this.props.creditCard.clientName}</div>;
  }

  renderSelectContainer() {
    let { clients } = this.state,
      clientOptions = client => {
        return (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        );
      },
      options = clients.map(clientOptions);

    return (
      <div className="select-container">
        <select
          name="clientId"
          defaultValue=""
          onChange={this.handleChange}
          className={this.state.clientId ? '' : 'placeholder-text'}
          required={true}
        >
          <option disabled hidden value="">
            Select
          </option>
          {options}
        </select>
        <ArrowDown className="select-arrow" />
      </div>
    );
  }

  handleSubmit = (e, elements, stripe) => {
    e.preventDefault();

    const { action } = this.props;

    if (action === 'add') {
      const { clientId, cardholder } = this.state;
      if (!clientId || !cardholder) {
        return false;
      }
    }

    this.handleClick(e, elements, stripe);
  };

  renderCreditCardDetails(action) {
    if (action === 'add') {
      return this.renderStripeCardElement();
    }

    const { creditCard } = this.props,
      { expirationMonth, expirationYear } = this.state;

    return (
      <div className="credit-card-info-lockup">
        <div className="credit-card-type-and-last-four">
          <div className={`${this.setCardTypeBackground(creditCard)}`}>{creditCard.cardTypeShortName}</div>
          <span className="u-margin-right-s">• • • •</span>
          {creditCard.lastFour}
        </div>
        <div className="credit-card-expiration-lockup">
          <input
            id="expirationMonth"
            type="text"
            name="expirationMonth"
            value={expirationMonth}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            autoComplete="off"
            placeholder="MM"
            required={true}
            maxLength={2}
          />
          <span>/</span>
          <input
            id="expirationYear"
            type="text"
            name="expirationYear"
            value={expirationYear}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            autoComplete="off"
            placeholder="YY"
            required={true}
            maxLength={2}
          />
        </div>
      </div>
    );
  }

  renderStripeCardElement() {
    return (
      <CardElement
        options={{
          style: {
            base: {
              fontFamily: 'Roboto, sans-serif',
              fontSize: '18px',
              color: '#444b68',
              '::placeholder': {
                color: '#aab7c4',
                fontStyle: 'italic'
              }
            },
            invalid: {
              color: '#9e2146'
            }
          }
        }}
      />
    );
  }

  displayForm(elements, stripe) {
    const { action } = this.props,
      { cardholder, clientId, expirationMonth, expirationYear, notes } = this.state;

    const buttonDisabled = !clientId || !cardholder || (action === 'edit' && (!expirationMonth || !expirationYear));

    return (
      <React.Fragment>
        <div>
          <div className="input-lockup">
            <label>CLIENT</label>
            {this.renderClientDetails(action)}
          </div>
          <TextInputField
            name="cardholder"
            value={cardholder}
            label="CARD HOLDER"
            type="text"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            required={true}
            showUnderline={true}
            placeholder={'Ford S. Riders'}
            autocomplete="off"
          />
          <div className="input-lockup">
            <label>CREDIT CARD</label>
            {this.renderCreditCardDetails(action)}
          </div>
          <TextInputField
            name="notes"
            label="NOTES (Optional)"
            value={notes}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            placeholder="E.g. Corporate Only"
            multiline={true}
          />
        </div>
        <ButtonRow
          action={action}
          disabled={buttonDisabled}
          onClickCancel={this.handleClick}
          onClickSuccess={e => this.handleClick(e, elements, stripe)}
          successText="Save"
        />
        {this.state.errors.length !== 0 ? (
          <div className="error">Hmm, something went wrong. Please try again.</div>
        ) : null}
      </React.Fragment>
    );
  }

  displayBody = () => {
    if (this.props.success) {
      return <div className="u-center-text">{this.successBody()}</div>;
    }

    const { action } = this.props;

    if (action === 'delete') {
      return (
        <React.Fragment>
          <p>You will not be able to undo this.</p>
          <ButtonRow
            action={action}
            cancelText="Never mind"
            onClickCancel={this.handleClick}
            onClickSuccess={this.handleClick}
            successText="Yes, Delete"
          />
          {this.state.errors.length !== 0 ? (
            <div className="error">Hmm, something went wrong. Please try again.</div>
          ) : null}
        </React.Fragment>
      );
    }

    if (action === 'edit') {
      return this.displayForm();
    }

    return (
      <Elements stripe={stripePromise}>
        <ElementsConsumer>{({ elements, stripe }) => this.displayForm(elements, stripe)}</ElementsConsumer>
      </Elements>
    );
  };

  render() {
    return (
      <div className="action-card">
        <div
          className="icon-container"
          onClick={e => {
            e.target.value = 'close';
            this.handleClick(e);
          }}
          ref={this.closeCard}
        >
          <DeleteIcon alt="Close" />
        </div>
        <div className="page-header page-header--h2">{this.displayHeader()}</div>
        <div className="body">{this.displayBody()}</div>
      </div>
    );
  }
}
