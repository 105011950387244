import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedComponent from '../../components/ProtectedComponent';
import RideTable from '../../components/table_components/RideTable';
import PrincipalColumns from '../../components/table_components/PrincipalColumns';
import left from '../../components/table_components/table_pagination/left.svg';
import AppStore from '../../components/images/apps/appStore.svg';
import GooglePlay from '../../components/images/apps/googlePlay.svg';
import assistantPhone from '../../components/images/apps/assistant@2x.png';
import '../MobileApp.scss';
import ReactGA from 'react-ga';

class InvitePrincipal extends ProtectedComponent {
  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="content-above-footer">
        <div className="main-content">
          <Link to="/apps" className="back">
            <img src={left} alt="" className="arrow" />
            BACK TO ALL MOBILE APPS
          </Link>
          <div className="header">Invite an Assistant to the Fortis Assistant App</div>
          <div className="content">
            <div className="table">
              <RideTable search={false} columnList={PrincipalColumns} class={'invite-table'} />
            </div>
            <div className="invite-card">
              <div className="features">
                <div className="features-header">Features</div>
                <div className="features-list">
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    Follow trips remotely with real-time notifications about arrivals and waypoints.
                  </div>
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    Enjoy direct communication with pilots and with Fortis’ 24/7 agents.
                  </div>
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    See information about chauffeurs assigned to your upcoming trips.
                  </div>
                </div>
                <div className="getApp-btn">
                  <a href="https://apps.apple.com/us/app/fortis-assistant/id792574234?ls=1">
                    <img src={AppStore} alt="" className="download-app" />
                  </a>
                  <a href="http://play.google.com/store/apps/details?id=com.fortisriders.fortisassistant.assistant">
                    <img src={GooglePlay} alt="" className="download-app" />
                  </a>
                </div>
              </div>
              <div className="phone">
                <img src={assistantPhone} alt="" className="phone-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvitePrincipal;
