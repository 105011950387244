import Api from './api';

class ClientsApi {
  index(cb) {
    let api = new Api();
    api.get(
      process.env.REACT_APP_GD2_URL + `/mobile_apis/client_booking/clients.json`,
      data => {
        cb(data.clients);
      },
      error => {
        console.error(error);
      }
    );
  }
}

export default ClientsApi;
