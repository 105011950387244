// https://blog.alexdevero.com/custom-styled-google-map-react/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import RecenterIcon from '../images/map-recenter.svg';

// Import custom styles to customize the style of Google Map
const styles = require('./GoogleMapStyles.json');

// Import custom icon for map marker
// You can use this if you need to support IE11 and lower.
const mapMarkerWaypoint = require('../images/map-pin-waypoint.svg');
const mapMarkerChauffeur = require('../images/map-pin-chauffeur.svg');

class GoogleMapComponentWithMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoRecenter: true,
      // initialZoom is the zoom level saved when Idle's called. Set to null means it's
      // not yet been set. When ZoomChanged is called, I check to see whether zoom has
      // "really" changed, if so then turn off autorecenter.
      initialZoom: null
    };
  }

  componentDidMount() {
    this.resizeMap();
  }

  // State updates when there's a new location from the chauffeur. So recenter
  // if we're autocentering.
  componentDidUpdate() {
    if (this.state.autoRecenter) {
      this.resizeMap();
    }
  }

  resizeMap = () => {
    // Here, we are checking if there is more than one waypoint or a chauffeur
    // location if there is the we will use fitBounds to include all waypoints
    // and potentially a chauffeur location as well. (defaultZoom if there is 1 pin)
    const { markers, chauffeurPosition } = this.props;
    if (markers[1] || chauffeurPosition.latitude) {
      const bounds = new window.google.maps.LatLngBounds();
      if (chauffeurPosition.latitude) {
        bounds.extend({ lat: Number(chauffeurPosition.latitude), lng: Number(chauffeurPosition.longitude) });
      }
      markers.map(marker => {
        bounds.extend(marker);
        return marker.id;
      });

      this._map.fitBounds(bounds, { bottom: 60, top: 60, left: 60, right: 60 });
    }
  };

  setAutoRecenter = newMode => {
    this.setState({ autoRecenter: newMode });
  };

  recenterButton = () => {
    const recenterIconStyle = {
      position: 'absolute',
      background: '#ffffff',
      bottom: 110,
      right: 10,
      height: 24,
      width: 24,
      radius: 10,
      padding: 8,
      borderRadius: 3
    };
    return (
      <img
        src={RecenterIcon}
        alt="Recenter Button"
        style={recenterIconStyle}
        onClick={() => this.setAutoRecenter(true)}
      />
    );
  };

  render() {
    const { markers, chauffeurPosition } = this.props;

    return (
      <div>
        <GoogleMap
          ref={map => (this._map = map)}
          defaultZoom={14}
          defaultCenter={{
            lat: markers[0].lat,
            lng: markers[0].lng
          }}
          options={{
            styles: styles, // change default map styles
            keyboardShortcuts: false,
            disableDefaultUI: true,
            draggable: true,
            scaleControl: true,
            scrollwheel: true,
            zoomControl: true,
            zoomControlOptions: { position: 9 }
          }}
          onDragStart={() => {
            this.setAutoRecenter(false);
          }}
          onZoomChanged={() => {
            if (this._map.getZoom() !== this.state.initialZoom && this.state.initialZoom !== null) {
              this.setAutoRecenter(false);
            }
          }}
          onIdle={() => {
            // saving initial zoom, now that the dust has settled
            if (this.state.initialZoom === null) {
              this.setState({ initialZoom: this._map.getZoom() });
            }
          }}
        >
          <span>
            {chauffeurPosition.latitude ? (
              <Marker
                icon={{
                  url: mapMarkerChauffeur
                }}
                position={{
                  lat: Number(chauffeurPosition.latitude),
                  lng: Number(chauffeurPosition.longitude)
                }}
                zIndex={markers.length}
              />
            ) : (
              ''
            )}
            {markers.map(marker => {
              marker.uniqId = (marker.stopNumber || markers.indexOf(marker)) + 1;
              return (
                <Marker
                  key={marker.uniqId}
                  icon={{
                    url: mapMarkerWaypoint
                  }}
                  position={{
                    lat: marker.lat,
                    lng: marker.lng
                  }}
                  label={{
                    text: String(marker.uniqId),
                    color: '#444B68',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontWeight: '500'
                  }}
                  zIndex={markers.length - marker.uniqId}
                />
              );
            })}
          </span>
        </GoogleMap>
        {this.recenterButton()}
      </div>
    );
  }
}

// Export Google Map component
export default withScriptjs(withGoogleMap(GoogleMapComponentWithMarker));

GoogleMapComponentWithMarker.propTypes = {
  chauffeurPosition: PropTypes.object.isRequired,
  markers: PropTypes.array.isRequired
};
