import React, { Component } from 'react';
import WaypointNote from './waypointNote';
import WaypointHeader from './waypointHeader';

export default class waypointDetail extends Component {
  noteContent = (location, flightInfo) => {
    if (!location) return <WaypointHeader name="As Directed" />
    const { location_meeting_procedures, location_notes, ...locationInfo } = location;
    return (
      <React.Fragment>
        <WaypointHeader {...locationInfo} {...flightInfo} />
        <WaypointNote note={location_meeting_procedures} header={'MEETING PROCEDURES'} />
        <WaypointNote note={location_notes} />
      </React.Fragment>
    );
  };

  render() {
    const { as_directed, stop_number, location, ...flightInfo } = this.props.waypoint;
    return (
      <span className="waypoint-content">
        <div className="point-circle">
          <div className="point-num">{stop_number + 1}</div>
        </div>
        <div className="waypoint-text-container">{this.noteContent(location, flightInfo)}</div>
      </span>
    );
  }
}
