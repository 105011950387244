import moment from 'moment-timezone';

export const TimeHandler = (data, typeFilter) => {
  if (typeFilter === 'Invoice') {
    if (data && data.length > 0) {
      data.forEach(invoice => {
        invoice.formattedInvoicedDate = moment.tz(invoice.invoiced_date, 'UTC').format('ddd, DD MMM YYYY');
        invoice.formattedDueDate = moment.tz(invoice.due_date, 'UTC').format('ddd, DD MMM YYYY');
      });
      data.reverse();
    }
  } else if (typeFilter === 'Ride') {
    if (data && data.length > 0) {
      data.forEach(data => {
        if (data.status.code === 'DEFERRED') {
          data.formattedStart = moment.tz(data.start, data.timezone_name).format('ddd, DD MMM YYYY');
        } else {
          data.formattedStart = moment.tz(data.start, data.timezone_name).format('ddd, DD MMM YYYY - LT z');
        }
      });
    }
  }
  return data;
};
