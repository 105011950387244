import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedComponent from '../../components/ProtectedComponent';
import RideTable from '../../components/table_components/RideTable';
import PrincipalColumns from '../../components/table_components/PrincipalColumns';
import left from '../../components/table_components/table_pagination/left.svg';
import AppStore from '../../components/images/apps/appStore.svg';
import GooglePlay from '../../components/images/apps/googlePlay.svg';
import principalPhone from '../../components/images/apps/principal@2x.png';
import '../MobileApp.scss';
import ReactGA from 'react-ga';

class InvitePrincipal extends ProtectedComponent {
  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="content-above-footer">
        <div className="main-content">
          <Link to="/apps" className="back">
            <img src={left} alt="" className="arrow" />
            BACK TO ALL MOBILE APPS
          </Link>
          <div className="header">Invite a Principal to the Fortis Principal App</div>
          <div className="content">
            <div className="table">
              <RideTable search={false} columnList={PrincipalColumns} class={'invite-table'} />
            </div>
            <div className="invite-card">
              <div className="features">
                <div className="features-header">Features</div>
                <div className="features-list">
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    Mark chauffeurs you prefer so we can prioritize them for future trips.
                  </div>
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    Contact your chauffeur by tapping a button or by sending a convenient, pre-filled message.
                  </div>
                  <div className="features-list-item">
                    <div className="features-list-bullet" />
                    Reward your best chauffeurs anywhere in the world with additional gratuity.
                  </div>
                </div>
                <div className="getApp-btn">
                  <a href="https://apps.apple.com/us/app/fortis-principal/id620115530?ls=1">
                    <img src={AppStore} alt="" className="download-app" />
                  </a>
                  <a href="http://play.google.com/store/apps/details?id=com.fortisriders.fortisprincipal.principal">
                    <img src={GooglePlay} alt="" className="download-app" />
                  </a>
                </div>
              </div>
              <div className="phone">
                <img src={principalPhone} alt="" className="phone-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvitePrincipal;
