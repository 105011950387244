import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RideNumber extends Component {
  render() {
    if (this.props.value !== null) {
      return <div>#{this.props.value}</div>;
    } else {
      return <div className="not-available" />;
    }
  }
}

RideNumber.propTypes = {
  value: PropTypes.number
};
