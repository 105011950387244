import React, { Component } from 'react';

export default class RideNumber extends Component {
  render() {
    if (this.props.value !== null) {
      return <div>{this.props.value.name}</div>;
    } else {
      return <div className="not-available" />;
    }
  }
}
