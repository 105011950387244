import matchSorter from 'match-sorter';

export default {
  // NOTE - this is a "filter all" DUMMY column
  // you can't HIDE it because then it wont FILTER
  // but it has a size of ZERO with no RESIZE and the
  // FILTER component is NULL (it adds a little to the front)
  // You culd possibly move it to the end
  Header: '',
  id: 'all',
  width: 0,
  resizable: false,
  sortable: false,
  Filter: () => {},
  getProps: () => {
    return {};
  },
  filterMethod: (filter, rows) => {
    // using match-sorter
    // it will take the content entered into the "filter"
    // and search for it in EITHER the firstName or lastName
    const result = matchSorter(rows, filter.value, {
      keys: [
        'id',
        'roadshow_id',
        '_original.passenger.name',
        '_original.formattedStart',
        '_original.chauffeur.first_name',
        '_original.portal_status'
      ],
      threshold: matchSorter.rankings.WORD_STARTS_WITH
    });
    return result;
  },
  filterAll: true
};
