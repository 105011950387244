import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Phone from '../images/Phone.svg';
import Mail from '../images/Email.svg';
import Close from '../images/close.svg';
import './PopupContactFortis.scss';

class PopupContactFortis extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="popup">
        <div className="popupContactFortis">
          <img src={Close} alt="Close" onClick={this.props.closePopup} className="popup-close" />
          <div className="header">Contact us any time, day or night.</div>
          <div className="body">
            <div className="contact">
              <a href={'mailto:' + t('common.reservationsEmail')}>
                <img className="icon icon--mail" src={Mail} alt="Fortis Mail" />
                {t('common.reservationsEmail')}
              </a>
            </div>
            <div className="vertical-rule"></div>
            <div className="contact">
              <a href={'tel:' + t('common.phone.raw')}>
                <img className="icon icon--phone" src={Phone} alt="Fortis Phone" /> {t('common.phone.display')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PopupContactFortis);
