import React, { Component } from 'react';
import RideNumbers from './rideNumbers';
import { Link } from 'react-router-dom';
import left from '../../components/table_components/table_pagination/left.svg';
import iconCheckmark from '../../components/images/iconCheckmark.svg';
import moment from 'moment';
import './rideHeader.scss';

export default class rideHeader extends Component {
  displayActivityStatus = (activityName, reservation) => {
    let activityTimestamp;

    if (activityName === 'COL') {
      activityTimestamp = reservation.chauffeur_on_location_at;
    } else if (activityName === 'POB') {
      activityTimestamp = reservation.passenger_in_vehicle_at;
    }

    if (activityTimestamp) {
      return moment(activityTimestamp)
        .tz(reservation.timezone_name)
        .format('LT z');
    }
  };

  render() {
    const { reservation } = this.props;
    let rideTitle;

    if (reservation) {
      const { passenger, waypoints } = reservation,
        paxName = passenger ? passenger.name : '',
        rideDate = moment.tz(reservation.start, reservation.timezone_name).format('MMM DD');

      const pickupCity = waypoints.find(waypoint => waypoint.waypoint_type === 'PICKUP').location.city;
      rideTitle = `${rideDate} - ${paxName} in ${pickupCity}`;
    }

    return (
      <div>
        <Link to="/reservations" className="rides-back">
          <img src={left} alt="" className="arrow" />
          RIDES
        </Link>
        <div className="ride-header">
          <div className="ride-header-info u-margin-right-m">
            <RideNumbers reservation={reservation} />
            <div className="ride-title">{rideTitle}</div>
          </div>
          <div className="ride-status u-margin-bottom-m">
            <div className={`${reservation.chauffeur_on_location_at ? 'ride-status__container' : 'u-hide'}`}>
              <img src={iconCheckmark} alt="" className="icon-checkmark" />
              <div className="ride-status__label-and-status">
                <div className="ride-status__label u-margin-right-s attribute-text">Chauffeur In Position: </div>
                <div className="ride-status__status attribute-text">
                  {this.displayActivityStatus('COL', reservation)}
                </div>
              </div>
            </div>
            <div className={`${reservation.passenger_in_vehicle_at ? 'ride-status__container' : 'u-hide'}`}>
              <img src={iconCheckmark} alt="" className="icon-checkmark" />
              <div className="ride-status__label-and-status">
                <div className="ride-status__label u-margin-right-s attribute-text">Passenger on Board: </div>
                <div className="ride-status__status attribute-text">
                  {this.displayActivityStatus('POB', reservation)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
