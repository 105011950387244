import React, { Component } from 'react';
import principalIcon from '../../components/images/ride_overview/principal.svg';
import chauffeurIcon from '../../components/images/ride_overview/chauffeur.svg';
import pickupIcon from '../../components/images/ride_overview/pickup.svg';
import totalPriceIcon from '../../components/images/ride_overview/totalPrice.svg';
import sprinterIcon from '../../components/images/ride_overview/sprinter.svg';
import boatIcon from '../../components/images/ride_overview/boat.svg';
import sedanIcon from '../../components/images/ride_overview/sedan.svg';
import suvIcon from '../../components/images/ride_overview/suv.svg';
import LoadingButton from '../../components/LoadingButton';
import axios from 'axios';
import { ReactComponent as FavoriteIcon } from '../../../src/Icons/icon-favorite.svg';

export default class rideOverviewDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      querying: false,
      favoritedChauffeur: this.props.favoritedChauffeur
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
  }

  componentDidMount() {
    if (
      parseInt(window.getComputedStyle(this.refs.detailText).height, 10) >
      parseInt(window.getComputedStyle(this.refs.detailIcon).height, 10)
    ) {
      this.setState({ longDetailText: true });
    }
  }

  overviewImage = category => {
    let defaultIcon;
    if (category === 'ESTIMATED TOTAL IN USD') {
      defaultIcon = totalPriceIcon;
    } else if (category === 'VEHICLE') {
      defaultIcon = this.vehicleIcon();
    } else if (category === 'CHAUFFEUR') {
      defaultIcon = chauffeurIcon;
    } else if (category === 'PICKUP DETAILS') {
      defaultIcon = pickupIcon;
    } else {
      defaultIcon = principalIcon;
    }

    if (category === 'CHAUFFEUR' && this.props.image) {
      return (
        <div ref="detailIcon" className="overview__chauffeur-avatar-container detail-icon">
          <img className="overview__chauffeur-avatar" alt="Chauffeur" src={this.props.image} />
        </div>
      );
    } else {
      return <img ref="detailIcon" src={defaultIcon} alt={this.props.alt} className="detail-icon" />;
    }
  };

  vehicleIcon = () => {
    let { content } = this.props;
    content = content.toLowerCase();
    if (content.includes('suv')) {
      return suvIcon;
    } else if (
      content.includes('mpv') ||
      content.includes('van') ||
      content.includes('bus') ||
      content.includes('coach')
    ) {
      return sprinterIcon;
    } else if (content.includes('boat')) {
      return boatIcon;
    } else {
      return sedanIcon;
    }
  };

  favorite() {
    let reservation = this.props.reservation,
      { passenger, chauffeur } = reservation;
    const route = `/mobile_apis/client_booking/passengers/${passenger.id}/preferred_chauffeurs/${chauffeur.id}`;
    const url = process.env.REACT_APP_GD2_URL + route;
    const method = this.state.favoritedChauffeur ? 'delete' : 'put';

    axios({
      method: method,
      url: url,
      data: {
        reservation: {
          id: reservation.id,
          passenger_preferred_chauffeur: !this.state.favoritedChauffeur
        }
      }
    })
      .then(() => {
        this.setState({
          favoritedChauffeur: !this.state.favoritedChauffeur,
          querying: false
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({ querying: false });
      });
  }

  handleClick() {
    this.setState({ querying: true });
    this.favorite();
  }

  handleHover() {
    if (this.state.favoritedChauffeur) {
      return null;
    }
    return (
      <div className="favorite-button-hover-container">
        Favoriting a chauffeur means we'll work to pair you with them again in the future.
      </div>
    );
  }

  faveButtonTitle() {
    return (
      <React.Fragment>
        <FavoriteIcon
          alt="Favorite Icon"
          className={`favorite-icon ${this.state.favoritedChauffeur ? 'favorite-icon--favorited' : ''}`}
        />
        {this.state.favoritedChauffeur ? 'Favorited' : 'Favorite'}
      </React.Fragment>
    );
  }

  displayFavoriteChauffeurButton(header, content, reservation) {
    if (header === 'CHAUFFEUR' && content !== null && reservation.passenger !== null) {
      return (
        <LoadingButton
          title={this.faveButtonTitle()}
          enabled={true}
          animating={this.state.querying}
          onClick={this.handleClick}
          onHover={this.handleHover}
          klass="button button--tertiary"
          buttonType="button"
        />
      );
    }
  }

  createDetailContent = (content, content_continued) => {
    if (!(content || content_continued)) {
      return 'Not Yet Available';
    } else {
      return (
        <div>
          {content}
          <br />
          {content_continued}
        </div>
      );
    }
  };

  render() {
    let { alias, alt, content, content_continued, header, reservation } = this.props;
    header = header || alt.toUpperCase();
    let { longDetailText } = this.state;

    return (
      <div className={`details${longDetailText ? ' details--top-align' : ''}`}>
        {this.overviewImage(header)}
        <div ref="detailText" className="detail">
          <div className="detail-header">{header}</div>
          <div className="detail-content">{this.createDetailContent(content, content_continued)}</div>
          <div className="detail-extra">{alias}</div>
          {this.displayFavoriteChauffeurButton(header, content, reservation)}
        </div>
      </div>
    );
  }
}
