import React, { Component } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';

export default class SignOut extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const { history } = this.props;

    axios
      .post(process.env.REACT_APP_GD2_URL + '/portal_apis/clients/account/logout.json', {})
      .then(data => {
        localStorage.clear();
        history.push('/sign-in');
      })
      .catch(error => {
        console.error(JSON.stringify(error));

        if (error.response) {
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error(error.message);
        }
        localStorage.clear();
        history.push('/sign-in');
      });
  }

  render() {
    return <div className="page-header u-center-text content-above-footer">See you next time!</div>;
  }
}
