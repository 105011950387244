import React, { Component } from 'react';
import PropTypes from 'prop-types';
import right from './right.svg';
import rightDouble from './right-double.svg';
import left from './left.svg';
import leftDouble from './left-double.svg';

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class ReactTablePagination extends Component {
  constructor(props) {
    super();
    this.changePage = this.changePage.bind(this);
    this.pageSizeChange = this.pageSizeChange.bind(this);
    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }
    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total <= 5) {
      return Array.from({ length: total }, (v, k) => k + 1);
    } else {
      if (page % 5 >= 0 && page > 3 && page + 2 < total) {
        return [page - 2, page - 1, page, page + 1, page + 2];
      } else if (page % 5 >= 0 && page > 3 && page + 2 >= total) {
        return [total - 4, total - 3, total - 2, total - 1, total];
      } else if (page % 5 >= 0 && page <= 3 && page >= 0) {
        return Array.from({ length: 5 }, (v, k) => k + 1);
      } else {
        return Array.from({ length: total }, (v, k) => k + 1);
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  pageSizeChange(e) {
    this.props.onPageSizeChange(e);
  }

  render() {
    const { showPageSizeOptions, pageSizeOptions, pageSize, pageSizeFiltered, data } = this.props;

    const pageSizeOptionsFiltered = pageSizeOptions.filter(function(e) {
      return e <= data.length;
    });
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    // If the returned results are fewer than the default # of rides per page, don't show pagination.
    if (pageSize > pageSizeFiltered) {
      return '';
    }

    return (
      <div className="pagination-section-all">
        <div className="grid-item">
          <div className="pagination-section styleSelect">
            SHOW:
            {showPageSizeOptions && (
              <select
                className="styleSelect"
                onChange={e => this.pageSizeChange(Number(e.target.value))}
                value={pageSize}
              >
                {pageSizeOptionsFiltered.map((option, i) => (
                  <option key={i} value={option}>
                    {`${option}`}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="line-sort--container">
            <div className="line-sort" />
          </div>
        </div>
        <div className="pagination-section grid-item">
          <div className="Table__prevPageWrapper">
            <PageButtonComponent
              disabled={activePage === 1}
              className={activePage === 1 ? 'Table__pageButton disabled-button' : 'Table__pageButton'}
              onClick={() => {
                this.changePage(1);
              }}
            >
              <img src={leftDouble} alt="" />
            </PageButtonComponent>
          </div>
          <div className="Table__prevPageWrapper">
            <PageButtonComponent
              disabled={activePage === 1}
              className={activePage === 1 ? 'Table__pageButton disabled-button' : 'Table__pageButton'}
              onClick={() => {
                this.changePage(activePage - 1);
              }}
            >
              <img src={left} alt="" />
            </PageButtonComponent>
          </div>
          <div className="Table__visiblePagesWrapper">
            {visiblePages.map((page, index, array) => {
              return (
                <PageButtonComponent
                  key={page}
                  className={activePage === page ? 'Table__pageButton Table__pageButton--active' : 'Table__pageButton'}
                  onClick={this.changePage.bind(null, page)}
                >
                  {array[index - 1] + 2 < page ? `...${page}` : page}
                </PageButtonComponent>
              );
            })}
          </div>
          <div className="Table__nextPageWrapper">
            <PageButtonComponent
              disabled={activePage === this.props.pages}
              className={activePage === this.props.pages ? 'Table__pageButton disabled-button' : 'Table__pageButton'}
              onClick={() => {
                if (activePage === this.props.pages) return;
                this.changePage(activePage + 1);
              }}
            >
              <img src={right} alt="" />
            </PageButtonComponent>
          </div>
          <div className="Table__nextPageWrapper">
            <PageButtonComponent
              disabled={activePage === this.props.pages}
              className={activePage === this.props.pages ? 'Table__pageButton disabled-button' : 'Table__pageButton'}
              onClick={() => {
                if (activePage === this.props.pages) return;
                this.changePage(this.props.pages);
              }}
            >
              <img src={rightDouble} alt="" />
            </PageButtonComponent>
          </div>
        </div>
      </div>
    );
  }
}

ReactTablePagination.propTypes = {
  showPageSizeOptions: PropTypes.bool.isRequired,
  pageSizeOptions: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired
};
