import React from 'react';
import axios from 'axios';
import ProtectedComponent from '../components/ProtectedComponent';
import { rideMount } from '../components/table_components/rideMount';
import RideHeader from './ride_header/rideHeader';
import RideDetails from './rideDetails';
import MapView from './mapView';
import FRMap from '../components/map_components/Map';
import ReactGA from 'react-ga';
import './rideView.scss';
import IncorrectURL from '../components/redirect_components/incorrectURL';
import iconRideTracking from '../components/images/iconRideTracking.svg';

class RideView extends ProtectedComponent {
  constructor(props) {
    super(props);
    const initialState = {
      chauffeurPosition: {},
      loading: true,
      portalReservations: [],
      reservationId: props.match.params.reservationId.trim(),
      show: true
    };
    this.state = { ...this.state, ...initialState };
  }

  componentDidMount() {
    super.componentDidMount();
    ReactGA.pageview(window.location.pathname + window.location.search);

    rideMount(this);
    this.getChauffeurLocationFromGD2();
    this.interval = setInterval(() => this.getChauffeurLocationFromGD2(), 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getChauffeurLocationFromGD2 = () => {
    const reservation = this.state.portalReservations.find(t => String(t.id) === this.state.reservationId);
    if (
      reservation &&
      reservation.chauffeur &&
      reservation.status.code === 'RESERVED' &&
      reservation.ride_tracking_status === 'AVAILABLE'
    ) {
      axios
        .get(
          process.env.REACT_APP_GD2_URL +
            `/mobile_apis/client_booking/reservations/${this.state.reservationId}/location_tracking.json`
        )
        .then(json => {
          const chauffeurPosition = json.data.chauffeur || {};
          this.setState({
            chauffeurPosition: chauffeurPosition
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      // TODO: Add a callback so we don't have to clear the interval here.
      clearInterval(this.interval);
    }
  };

  waypointsToMarkers = reservation => {
    let markers = [];
    reservation.waypoints.forEach(waypoint => {
      if (!waypoint.location) return null;
      markers.push({
        lat: Number(waypoint.location.latitude),
        lng: Number(waypoint.location.longitude),
        stopNumber: waypoint.stop_number
      });
    });
    return markers;
  };

  toggleMap = e => {
    e.preventDefault();
    this.setState({
      show: !this.state.show
    });
  };

  trackingOverlay = status => {
    let statusText;
    if (status === 'NOT-AVAILABLE') statusText = 'Ride tracking is not available.';
    else if (status === 'WAITING-ON-EN-ROUTE-TO-PICKUP')
      statusText = 'Ride tracking will be available once the chauffeur is en route.';
    else if (status === 'FORTIS-RELEASED') statusText = 'Ride tracking is not available on completed rides.';

    if (status === 'AVAILABLE') return;
    else {
      return (
        <div className="tracking-container">
          <img src={iconRideTracking} className="icon-ride-tracking" alt="Ride Tracking Indicator" />
          <span className="tracking-text">{statusText}</span>
        </div>
      );
    }
  };

  render() {
    const { chauffeurPosition, loading, portalReservations, reservationId, show } = this.state;
    if (loading) {
      /* TODO: make a LoadingIndicator component */
      return (
        <div className="page-loading-indicator-container content-above-footer">
          <div className="circle-loader circle-loader--large" />
        </div>
      );
    }

    const styles = {
      width: '100%',
      height: '100%'
    };

    let reservation;

    if (portalReservations.length > 0) {
      reservation = portalReservations.find(t => String(t.id) === reservationId);
    }

    if (!reservation) return <IncorrectURL message={`Ride #${reservationId} does not exist.`} />;

    return (
      <div className="content-above-footer">
        <div className="main-content">
          <RideHeader reservation={reservation} />
          <div className="ride-content">
            <RideDetails show={show} reservation={reservation} />
            <MapView show={show} onShow={this.toggleMap} />
            <div className="Map">
              {this.trackingOverlay(reservation.ride_tracking_status)}
              <div style={styles}>
                <FRMap
                  googleMapURL="https://maps.googleapis.com/maps/api/js?client=gme-fortisridersacquisition&v=3&libraries=places&language=en"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  markers={this.waypointsToMarkers(reservation)}
                  chauffeurPosition={chauffeurPosition}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RideView;
