import React, { Component } from 'react';
import './rideView.scss';

export default class RideDetails extends Component {
  render() {
    if (this.props.show) {
      return (
        <div className="map-toggle" onClick={this.props.onShow.bind(this)}>
          <div className="map-toggle__half map-toggle__half--top">
            <div className="map-toggle__handle" />
          </div>
          <div className="map-toggle__arrow-cutout" />
          <div className="map-toggle__arrow-cutout" />
          <div className="map-toggle__half map-toggle__half--bottom">
            <div className="map-toggle__handle" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="ride-details-show" onClick={this.props.onShow.bind(this)}>
          <div className="vertical-text">RIDE DETAILS</div>
          <div className="arrow" />
        </div>
      );
    }
  }
}
