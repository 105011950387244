import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations_en from './locales/en.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translations_en }
  },
  lng: 'en'
});

export default i18n;
