import React, { Component } from 'react';
import WaypointDetails from './waypointDetails';

export default class rideWaypoints extends Component {
  render() {
    const { waypoints } = this.props.reservation;
    return (
      <div className="itinerary-content">
        <div className="waypoint-list">
          {waypoints
            .sort((a, b) => a.stop_number - b.stop_number)
            .map((waypoint, i) => (
              <WaypointDetails waypoint={waypoint} key={i} />
            ))}
        </div>
      </div>
    );
  }
}
