import React, { Component } from 'react';
import RedirectURL from '../components/redirect_components/incorrectURL';
import './Password.scss';

export default class PasswordSuccess extends Component {
  render() {
    return (
      <div>
        <RedirectURL title={'Change Password'} message={'Your password has been changed.'} />
      </div>
    );
  }
}
