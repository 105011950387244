import React from 'react';
import { Redirect } from 'react-router-dom';
import ProtectedComponent from '../ProtectedComponent';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import SearchBar from './searchBar';
import pagination from './table_pagination/pagination';
import { rideMount } from './rideMount';
import './styles/defaultTable.scss';
import './styles/inviteTable.scss';
import './styles/invoiceTable.scss';
import './styles/pagination.scss';

export default class RideTable extends ProtectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      filterAll: '',
      loading: true,
      pageSize: props.pageSize || 10,
      portalReservations: [],
      redirectToRideId: null
    };
    this.filterAll = this.filterAll.bind(this);
    this.pageSizeChange = this.pageSizeChange.bind(this);
    this.rowClickHandler = this.rowClickHandler.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.user) {
      rideMount(this);
    }
  }

  onFilteredChange(filtered) {
    // extra check for the "filterAll"
    if (filtered.length > 1 && this.state.filterAll.length) {
      // NOTE: this removes any FILTER ALL filter
      const filterAll = '';
      this.setState({
        filtered: filtered.filter(item => item.id !== 'all'),
        filterAll
      });
    } else this.setState({ filtered });
  }

  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: 'all', value: filterAll }];
    // NOTE: this completely clears any COLUMN filters
    this.setState({ filterAll, filtered });
  }

  pageSizeChange(e) {
    const pageSize = e;
    this.setState({ pageSize });
  }

  dataCollection = () => {
    if (this.props.typeFilter === 'Ride') {
      return this.dataCollectionForRides();
    } else if (this.props.typeFilter === 'Invoice') {
      return this.dataCollectionForInvoices();
    } else {
      console.error('typeFilter not set');
      return this.state.portalReservations;
    }
  };

  dataCollectionForInvoices = () => {
    return this.state.portalReservations.sort((a, b) => b.due_date.localeCompare(a.due_date));
  };

  dataCollectionForRides = () => {
    if (this.props.sortOrder === 'desc') {
      return this.state.portalReservations.sort((a, b) => b.start.localeCompare(a.start));
    }
    return this.state.portalReservations.sort((a, b) => a.start.localeCompare(b.start));
  };

  rowClickHandler(row) {
    if (this.props.clickHandler) {
      this.props.clickHandler(row);
    } else {
      this.setState({
        redirectToRideId: row.original.id
      });
    }
  }

  render() {
    if (this.state.redirectToRideId) {
      return <Redirect push to={`/reservations/${this.state.redirectToRideId}`} />;
    }

    if (this.state.loading) {
      /* TODO: make a LoadingIndicator component */
      return (
        <div className="page-loading-indicator-container">
          <div className="circle-loader circle-loader--large" />
        </div>
      );
    }

    const { noRecordsFoundMessage = 'No rides found.' } = this.props;
    const data = this.dataCollection();
    const pageSizeFiltered = data.length;

    if (data.length > 0) {
      return (
        <div className={this.props.class !== 'default' ? this.props.class : null}>
          <SearchBar
            enabled={this.props.search}
            value={this.state.filterAll}
            onChange={this.filterAll}
            className={this.props.class}
            placeholder={this.props.placeholder}
          />
          <ReactTable
            pageSizeOptions={[5, 10, 25, 50, 100]}
            onFilteredChange={this.onFilteredChange.bind(this)}
            filtered={this.state.filtered}
            loading={this.state.loading}
            data={data}
            columns={this.props.columnList}
            getTrProps={(state, row) => ({
              onClick: () => this.rowClickHandler(row)
            })}
            getTdProps={() => ({
              tablereference: this.props.parentTable
            })}
            PaginationComponent={pagination}
            pageSizeFiltered={pageSizeFiltered}
            pageSize={this.state.pageSize}
            onPageSizeChange={this.pageSizeChange}
            minRows={0}
          />
        </div>
      );
    } else {
      return (
        <div className={this.props.class}>
          <div className="noData-card">
            <div className="noData-content">{noRecordsFoundMessage}</div>
          </div>
        </div>
      );
    }
  }
}

RideTable.propTypes = {
  search: PropTypes.bool.isRequired,
  columnList: PropTypes.array.isRequired,
  class: PropTypes.string.isRequired
};
