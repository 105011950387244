import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Close from '../images/close.svg';
import LoadingButton from '../../components/LoadingButton';
import axios from 'axios';
import './InvoiceRequestPopup.scss';

class InvoiceRequestPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageSent: false,
      messageError: false,
      querying: false
    };
  }

  requestEmail() {
    this.setState({ querying: true });
    axios
      .post(process.env.REACT_APP_GD2_URL + this.props.url, {
        invoice_id: this.props.invoiceId,
        email: this.props.clientEmail
      })
      .then(json => {
        this.setState({ messageSent: true, messageError: false, querying: false });
      })
      .catch(err => {
        this.setState({ messageSent: false, messageError: true, querying: false });
      });
  }

  getBodyContent() {
    const { t } = this.props;
    if (this.state.messageSent) {
      return (
        <div className="popup-body">
          <h3>Sent Invoice {this.props.invoiceNumber}.</h3>
          <p>
            A PDF invoice was sent to <span>{this.props.clientEmail}</span>.
          </p>
        </div>
      );
    } else if (this.state.messageError) {
      return (
        <div className="popup-body">
          <h3>"Houston, we have a problem..."</h3>
          <p>Something went wrong while trying to send this invoice. Please try again shortly.</p>
        </div>
      );
    } else {
      return (
        <div className="popup-body">
          <h3>Confirm request for Invoice {this.props.invoiceNumber}.</h3>
          <p>
            Are you sure you want a PDF invoice sent to you at <span>{this.props.clientEmail}</span>?
          </p>
          <p>
            <small>
              If you need additional access or help, contact us at{' '}
              <a href={'tel:' + t('common.phone.raw')}>{t('common.phone.display')}</a> or{' '}
              <a href={'mailto:' + t('common.accountingEmail')}>{t('common.accountingEmail')}</a>.
            </small>
          </p>
        </div>
      );
    }
  }

  getActionsContent() {
    if (this.state.messageSent || this.state.messageError) {
      return (
        <div className="popup-actions">
          <button onClick={this.props.closePopup}>Dismiss</button>
        </div>
      );
    } else {
      return (
        <div className="popup-actions">
          <button onClick={this.props.closePopup} className="button-text-only">
            Cancel
          </button>
          <LoadingButton
            title="Yes, Send PDF"
            enabled={!this.state.querying}
            onClick={this.requestEmail.bind(this)}
            animating={this.state.querying}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="popup" onClick={this.props.closePopup}>
        <div className="popupInvoice">
          <img src={Close} alt="" onClick={this.props.closePopup} className="popup-close" />
          {this.getBodyContent.bind(this)()}
          {this.getActionsContent.bind(this)()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(InvoiceRequestPopup);
