import React, { Component } from 'react';
import note_icon from '../components/images/ride_overview/notes.svg';
import CollapsableText from '../components/CollapsableText';

export default class rideNote extends Component {
  render() {
    const { note } = this.props;
    if (!note) return null;
    return (
      <div className="note">
        <div>
          <img src={note_icon} alt="" className="note-icon" />
        </div>
        <CollapsableText text={note} fadeType="rideNote" />
      </div>
    );
  }
}
